<template>
  <tr class="table-row">
    <td class="table-cell table-id">
      <router-link :to="{ name: 'Exchanges', query: { clientId: clientUser.id } }">
        {{ clientUser.id }}
      </router-link>
    </td>
    <td class="table-cell table-createDate">
      {{ dateFormatter(clientUser.createDate) }}
    </td>
    <td class="table-cell table-email">
      <div class="email-container">
        <span class="email-text">{{ clientUser.email ? clientUser.email : '-' }}</span>
        <img
          v-if="clientUser.isEmailVerified === true && clientUser.email"
          class="email-icon"
          src="../../../assets/img/icons/true.svg"
          alt=""
        >
        <img
          v-if="clientUser.isEmailVerified === false && clientUser.email"
          class="email-icon"
          src="../../../assets/img/icons/false.svg"
          alt=""
        >
      </div>
    </td>
    <td class="table-cell table-web3">
      {{ clientUser.walletAddress ? web3AddressFormatter(clientUser.walletAddress) : '-' }}
    </td>
    <td class="table-cell table-name">
      {{ clientUser.name ? clientUser.name : '-' }}
    </td>
    <td class="table-cell table-discount">
      <input
        v-model="discount"
        type="number"
        @input="validateDiscount"
        @keydown="validateDiscount"
      >
      <div
        v-if="discount !== currentDiscount"
        class="save"
        @click="editDiscountHandler"
      >
        Ok
      </div>
    </td>
    <td class="table-cell table-order">
      <span>{{ clientUser.statsRecord ? clientUser.statsRecord.ordersCount : 0 }}</span>/<span style="color: #00B18B">{{ clientUser.statsRecord ? clientUser.statsRecord.ordersCompleted : 0 }}</span>
    </td>
    <td class="table-cell table-status">
      <div
        ref="statusDropdown"
        class="status-button"
        :class="{
          'new': currentStatus === 0,
          'active': currentStatus === 1,
          'blocked': currentStatus === 2
        }"
        @click.stop="statusDropdownHandler()"
      >
        {{ currentStatus === 0 ? 'New' : currentStatus === 1 ? 'Active' : 'Blocked' }}
      </div>
      <div
        v-if="isOpen"
        class="dropdown"
        :class="{'position-top': positionOfDropdown === 'top'}"
      >
        <div
          class="dropdown-item"
          @click.stop="setStatusHandler(0)"
        >
          <div>
            <span
              class="status-label new"
            >
              N
            </span>
            New
          </div>
          <svg
            v-if="currentStatus === 0"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4068_2939)">
              <path
                d="M7.49896 13.5L4.58229 10.5833C4.25729 10.2583 3.74063 10.2583 3.41563 10.5833C3.09062 10.9083 3.09062 11.425 3.41563 11.75L6.90729 15.2416C7.23229 15.5666 7.75729 15.5666 8.08229 15.2416L16.9156 6.41664C17.2406 6.09164 17.2406 5.57498 16.9156 5.24998C16.5906 4.92498 16.074 4.92498 15.749 5.24998L7.49896 13.5Z"
                fill="#0A68F7"
              />
            </g>
            <defs>
              <clipPath id="clip0_4068_2939">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div
          class="dropdown-item"
          @click.stop="setStatusHandler(1)"
        >
          <div>
            <span
              class="status-label active"
            >
              A
            </span>
            Active
          </div>
          <svg
            v-if="currentStatus === 1"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4068_2939)">
              <path
                d="M7.49896 13.5L4.58229 10.5833C4.25729 10.2583 3.74063 10.2583 3.41563 10.5833C3.09062 10.9083 3.09062 11.425 3.41563 11.75L6.90729 15.2416C7.23229 15.5666 7.75729 15.5666 8.08229 15.2416L16.9156 6.41664C17.2406 6.09164 17.2406 5.57498 16.9156 5.24998C16.5906 4.92498 16.074 4.92498 15.749 5.24998L7.49896 13.5Z"
                fill="#0A68F7"
              />
            </g>
            <defs>
              <clipPath id="clip0_4068_2939">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div
          class="dropdown-item"
          @click.stop="setStatusHandler(2)"
        >
          <div>
            <span
              class="status-label blocked"
            >
              B
            </span>
            Blocked
          </div>
          <svg
            v-if="currentStatus === 2"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4068_2939)">
              <path
                d="M7.49896 13.5L4.58229 10.5833C4.25729 10.2583 3.74063 10.2583 3.41563 10.5833C3.09062 10.9083 3.09062 11.425 3.41563 11.75L6.90729 15.2416C7.23229 15.5666 7.75729 15.5666 8.08229 15.2416L16.9156 6.41664C17.2406 6.09164 17.2406 5.57498 16.9156 5.24998C16.5906 4.92498 16.074 4.92498 15.749 5.24998L7.49896 13.5Z"
                fill="#0A68F7"
              />
            </g>
            <defs>
              <clipPath id="clip0_4068_2939">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import {mapGetters} from 'vuex';
import moment from 'moment';

import UserService from '../../../services/user.service'
import PartnerService from '../../../services/partner.service'
import dayjs from 'dayjs';
import AdminService from '@/services/admin.service';


export default {
  name: "ClientUserListItem",
  components: {

  },
  props: {
    clientUser: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  },
emits: ['change-fav', 'toggle-dropdown', 'close-dropdown'],
  data() {
    return {
      dateFormat: 'DD.MM.YYYY',
      positionOfDropdown: 'bottom',
      discount: 0,
      currentDiscount: 0,
      currentStatus: null,
    }
  },
  computed: {
    ...mapGetters({
      UserItem: 'getUserItem',
      Notification: 'getNotification',
    }),
  },
  mounted() {
    this.$store.dispatch('getPartnersProgram');
    this.scrollHandler();
    this.discount = this.clientUser.discount
    this.currentDiscount = this.clientUser.discount
    this.currentStatus = this.clientUser.status

    document.addEventListener('click', this.statusClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.statusClickOutside);
  },
  methods: {
    dateFormatter(value) {
      return dayjs(value).format(this.dateFormat)
    },
    scrollHandler() {
      const table = document.querySelector('.table-block-table');

      table.addEventListener('scroll', () => {
        const tds = document.querySelectorAll('.table-id');

        tds.forEach((el) => {
          el.classList.add('sticky');

          if (table.scrollLeft < 20) {
            el.classList.remove('sticky');
          }
        });
      });
    },
    dataFormatting(value) {
      return moment(value).format(this.dateFormat);
    },
    web3AddressFormatter(address) {
      const maxLength = address.length
      const valEndSlice = address.length - 8
      return address.slice(0, 8) + '...'+  address.slice(valEndSlice, maxLength)
    },
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);
    },
    editDiscountHandler() {
      const query = {
        discount: this.discount,
        status: this.currentStatus,
        coverStyle: this.clientUser.coverStyle,
      }
      return AdminService.editClient(this.clientUser.id,query).then((result) => {
        if (result.status === 200) {
          this.currentDiscount = this.discount
        }
      }).catch((error) => {

      })
    },
    validateDiscount() {
      if (this.discount < 0 || !this.discount) {
        this.discount = 0;
      } else if (this.discount > 100) {
        this.discount = 100;
      }
    },
    statusDropdownHandler() {
      this.setDropdownPosition()
      this.$emit('toggle-dropdown', this.clientUser.id);
    },
    setDropdownPosition() {
      const statusDropdown = this.$refs.statusDropdown;
      const rect = statusDropdown.getBoundingClientRect();
      const dropdownHeight = 304;

      if (rect.bottom + dropdownHeight > window.innerHeight) {
        this.positionOfDropdown = 'top';
      } else {
        this.positionOfDropdown = 'bottom';
      }
    },
    statusClickOutside(event) {
      if (this.$refs.statusDropdown && !this.$refs.statusDropdown.contains(event.target)) {
        this.$emit('close-dropdown');
      }
    },
    setStatusHandler(status) {
      const query = {
        discount: this.currentDiscount,
        status: status,
        coverStyle: this.clientUser.coverStyle,
      }
      return AdminService.editClient(this.clientUser.id,query).then((result) => {
        if (result.status === 200) {
          this.currentStatus = status
          this.$emit('close-dropdown');
        }
      }).catch((error) => {

      })
    }
  },
}
</script>

<style scoped lang="scss">
.table-id {
  cursor: pointer;
  >a {
    color: #1B1A1C;
  }
}

.table-email {
  display: table-cell;
  vertical-align: middle;
}

.table-name {
  word-break: break-all;
}

.email-container {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.email-text {
  font-size: 14px;
  color: #333;
}

.table-discount {
  position: relative;

  >input {
    position: relative;
    width: 97px;
    border: 1px solid #DFE0EB;
    border-radius: 8px;
    padding: 2px 6px;
    box-sizing: border-box;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: none;
    appearance: none;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .save {
    position: absolute;
    display: flex;
    z-index: 10;
    left: 53px;
    top: 15px;
    width: 40px;
    height: 26px;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 5px 7px;
    background: #0A68F7;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    box-sizing: border-box;
  }
}

.table-status {
  position: relative;

  .status-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 88px;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.12px;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s all;
  }

  .status-button.new {
    background: #A1BBD9;
  }

  .status-button.active {
    background: #02C076;
  }

  .status-button.blocked {
    background: #FF603D;
  }

  .dropdown {
    position: absolute;
    background: white;
    top: 45px;
    right: -6px;
    width: 180px;
    z-index: 999;
    border-radius: 8px;
    padding: 8px 0;
    box-shadow: 0 1px 4px 0 rgba(6, 59, 122, 0.12), 0 4px 24px 0 rgba(6, 59, 122, 0.08);

    @media (max-width: 1079px) {
      top: 41px;
      left: -104px;

      @media (max-width: 819px) {
        top: 10px;
        left: -183px;
      }
    }

    &.position-top {
      top: -105px;
      /*@media (max-width: 1079px) {
        top: -300px;
        @media (max-width: 819px) {
          top: -271px;
        }
      }*/
    }

    .dropdown-item {
      padding: 6px 12px;
      color: #1A242B;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background: #F0F6FF;
      }

      .status-label {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        width: 18px;
        height: 18px;
        color: #FFF;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.12px;
        border-radius: 4px;
      }

      .status-label.new {
        background: #A1BBD9;
      }

      .status-label.active {
        background: #02C076;
      }

      .status-label.blocked {
        background: #FF603D;
      }
    }
  }
}
</style>
