<script>

import {mapGetters} from 'vuex';
import CustomSelect from '@/pages/balance/balance-total/partials/MirrorSelect.vue';
import OrderService from '@/services/order.service';


export default {
  name: "ReBalanceMirror",
  components: {CustomSelect},
  data() {
    return {
      type: '',
      token: '',
      availableNodeBalance: null,
      availableMarketBalance: null,
      fromSelectValue: null,
      toSelectValue: null,
      selectOptions: [],
      amount: '',
      mfa: '',
      mfaError: false,
      mfaErrorText: null,
      amountError: false
    }
  },
  computed: {
    ...mapGetters({
      reBalanceMirrorData: 'getReBalanceMirror'
    }),
  },
  created() {
    if (this.reBalanceMirrorData) {
      this.type = this.reBalanceMirrorData.type;
      this.token = this.reBalanceMirrorData.token;
      this.availableNodeBalance = this.reBalanceMirrorData.availableNodeBalance;
      this.availableMarketBalance = this.reBalanceMirrorData.availableMarketBalance;
      this.selectOptions = [{name: 'Node', value: this.availableNodeBalance, token: this.token},{name: 'Market', value: this.availableMarketBalance, token: this.token}];

      if (this.type === 'node') {
        this.fromSelectValue = {name: 'Node', value: this.availableNodeBalance, token: this.token};
        this.toSelectValue = {name: 'Market', value: this.availableMarketBalance, token: this.token};
      } else {
        this.fromSelectValue = {name: 'Market', value: this.availableMarketBalance, token: this.token};
        this.toSelectValue = {name: 'Node', value: this.availableNodeBalance, token: this.token};
      }
    }
  },
  methods: {
    closeModal() {
      this.$store.commit('setReBalanceMirror', null);
    },
    handleClickOutside(event) {
      if (event.target.classList.contains('reBalanceMirrorContainer')) {
        this.closeModal();
      }
    },
    handleChange(selectedType, selectedOption) {
      if (selectedType === 'from') {
        this.fromSelectValue = selectedOption;
        this.toSelectValue = this.selectOptions.find(
          o => o.name !== selectedOption.name
        );
      } else  {
        this.toSelectValue = selectedOption;
        this.fromSelectValue = this.selectOptions.find(
          o => o.name !== selectedOption.name
        );
      }
      this.amount = ''
      this.amountError = false
    },
    checkAmount(e) {
      e.target.value = e.target.value
        .replace(/,/g, '.')
        .replace(/[^0-9.]/g, '')
        .replace(/(\..*?)\./g, '$1')
        .replace(/^0+(\d)/, '$1')
        .replace(/^\./, '0.');

      this.amountError = +this.amount > this.fromSelectValue.value;
    },
    checkMFA() {
      this.mfaErrorText = null
      this.mfaError = false
      this.mfaError = this.mfa.length < 4 && this.mfa !== '';
    },
    submitHandler() {
      console.log(+this.amount)
      if (this.mfa === '') {
        this.mfaError = true
      }

      if (this.amount === '' || +this.amount === 0) {
        this.amountError = true
      }

      if (this.amountError || this.mfaError) {
        return false
      }

      const network =
        this.toSelectValue.token.includes('_') ? this.toSelectValue.token.split('_')[1] : this.toSelectValue.token
      const coin =
      this.toSelectValue.token.includes('_') ? this.toSelectValue.token.split('_')[0] : this.toSelectValue.token

      const query = {
        mfaCode: this.mfa,
        coin: coin,
        network: network,
        mode: this.toSelectValue.name,
        amount: this.amount
      }

      OrderService.rebalanceMirror(query).then((res) => {
        if (res.status === 200) {
          this.successPopup();
          this.closeModal();
        }
      }).catch((error) => {
          console.log(error.response.data.message)
          if (error.response.data.message === 'MFACode not valid or expired.') {
            this.mfaErrorText = error.response.data.message
            this.mfaError = true
          } else {
            this.errorPopup(error.response.data.message);
            this.closeModal();
          }
      })
    },
    successPopup() {
      this.$store.commit('setSuccess', {
        name: 'Rebalance mirror success',
      });
    },
    errorPopup(error) {
      this.$store.commit('setError', {
        name: 'Rebalance mirror error',
        value: error,
      });
    },
  }
}
</script>

<template>
  <div
    class="reBalanceMirrorContainer"
    tabindex="0"
    @click.stop="handleClickOutside"
  >
    <div class="reBalanceMirrorContainer-form">
      <div>
        <div class="reBalanceMirrorContainer-form-head">
          <div class="reBalanceMirrorContainer-form-title">
            Rebalance
          </div>
          <div
            class="reBalanceMirrorContainer-form-close"
            @click="closeModal"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4519_84506)">
                <path
                  d="M18.2987 5.7107C17.9087 5.3207 17.2787 5.3207 16.8887 5.7107L11.9988 10.5907L7.10875 5.7007C6.71875 5.3107 6.08875 5.3107 5.69875 5.7007C5.30875 6.0907 5.30875 6.7207 5.69875 7.1107L10.5888 12.0007L5.69875 16.8907C5.30875 17.2807 5.30875 17.9107 5.69875 18.3007C6.08875 18.6907 6.71875 18.6907 7.10875 18.3007L11.9988 13.4107L16.8887 18.3007C17.2787 18.6907 17.9087 18.6907 18.2987 18.3007C18.6887 17.9107 18.6887 17.2807 18.2987 16.8907L13.4087 12.0007L18.2987 7.1107C18.6787 6.7307 18.6787 6.0907 18.2987 5.7107Z"
                  fill="#9FA2B4"
                />
              </g>
              <defs>
                <clipPath id="clip0_4519_84506">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>

        <div class="reBalanceMirrorContainer-form-inputs">
          <!-- From -->
          <div
            class="reBalanceMirrorContainer-form-inputs-item"
          >
            <div class="desc">
              From
            </div>
            <CustomSelect
              :key="fromSelectValue.name"
              :options="selectOptions"
              :value-input="fromSelectValue"
              @change-select="({option}) => handleChange('from',option)"
            />
          </div>
          <!-- To -->
          <div
            class="reBalanceMirrorContainer-form-inputs-item"
            :class="{'reBalanceMirrorContainer-form-inputs-item-error': amountError}"
          >
            <div class="desc">
              To
            </div>
            <CustomSelect
              :key="toSelectValue.name"
              :options="selectOptions"
              :value-input="toSelectValue"
              @change-select="({option}) => handleChange('to',option)"
            />
          </div>
          <!-- Amount -->
          <div
            class="reBalanceMirrorContainer-form-inputs-item"
            :class="{'reBalanceMirrorContainer-form-inputs-item-error': amountError}"
          >
            <div class="desc">
              Amount
            </div>
            <input
              v-model="amount"
              type="text"
              @input="checkAmount"
            >
          </div>
          <!-- otp -->
          <div
            class="reBalanceMirrorContainer-form-inputs-item"
            :class="{'reBalanceMirrorContainer-form-inputs-item-error': mfaError}"
          >
            <div class="desc">
              OTP code
            </div>
            <input
              v-model="mfa"
              type="text"
              @input="checkMFA"
            >
            <span
              v-if="mfaErrorText"
              class="error-text"
            >
              {{ mfaErrorText }}
            </span>
          </div>
        </div>
      </div>

      <div class="reBalanceMirrorContainer-form-buttons">
        <div
          class="reBalanceMirrorContainer-form-buttons-item cancel"
          @click="closeModal"
        >
          Cancel
        </div>

        <div
          class="reBalanceMirrorContainer-form-buttons-item submit"
          @click="submitHandler"
        >
          Confirm
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.reBalanceMirrorContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  height: 100vh;
  z-index: 99;
  background: rgba(67, 40, 164, 0.54);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  overflow: auto;
  display: grid;
  place-items: center;


  &-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 6px 24px rgba(27, 26, 28, 0.16);
    max-width: 480px;
    min-height: 509px;
    width: 100%;
    position: relative;
    background: white;
    border-radius: 16px;
    box-sizing: border-box;

    &_warning {

      .mirrorWithdraw-form-head {
        padding-top: 24px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .mirrorWithdraw-form-inputs {
        padding-bottom: 0;

        &-item__text {
          .text {
            text-align: left;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      .error-content-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 419px;
      }

    }

    .mirrorWithdraw-info {
      padding: 0 32px;

      .title {
        margin-bottom: 12px;
        color: #1B1A1C;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
      }

      .description {
        margin-bottom: 32px;
        color: #1B1A1C;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .info-row {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        gap: 6px;

        span:first-child {
          color: #9FA2B4;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }

        span:last-child {
          color: #1B1A1C;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          word-break: break-all;
        }
      }
    }

    .trade-loader {
      height: 509px;
    }

    /*@media (max-width: 1079px) {
      margin: 0;
      height: 100vh;
      padding-bottom: 20px;
      border-radius: 0;
      overflow: auto;
      flex-direction: column;
      box-sizing: border-box;
    }*/

    &-title {
      font-size: 24px;
      line-height: 28px;
      color: #1B1A1C;
      font-weight: 700;
      padding: 24px 24px 32px;
      box-sizing: border-box;
    }

    &-icon {
      display: grid;
      place-items: center;
      margin-bottom: 12px;
    }

    &-close {
      position: absolute;
      right: 16px;
      top: 16px;
      display: grid;
      place-items: center;
      width: 24px;
      height: 24px;
      cursor: pointer;

      svg {
        path {
          transition: all .3s ease-in-out;
        }
      }

      &:hover {
        svg {
          path {
            fill: #0A68F7;
          }
        }
      }
    }

    &-inputs {
      padding: 0 24px 40px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 24px;

      &-item {
        width: 100%;
        position: relative;

        /*@media (max-width: 1079px) {
          max-width: 100%;
        }*/

        &_input {
          position: relative;
        }

        &__text {
          display: flex;
          flex-direction: column;
          align-items: center;

          .title {
            color: #1B1A1C;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
          }

          .text {
            margin-top: 12px;
            color: var(--black, #1B1A1C);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            letter-spacing: 0.2px;
          }
        }

        &-network {
          height: 100%;
          position: absolute;
          z-index: 9;
          right: 16px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 12px;
          color: #9FA2B4;
          text-align: right;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;

          .max-value {
            color: #0A68F7;
            font-weight: 700;

            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }

        .balance-available {
          margin-top: 5px;
          font-size: 12px;
          color: #9FA2B4;
        }


        .desc {
          font-size: 14px;
          line-height: 16px;
          color: #0A68F7;
          margin-bottom: 8px;
        }

        input {
          width: 100%;
          font-size: 14px;
          line-height: 18px;
          color: #1B1A1C;
          border: 1px solid #DFE0EB;
          border-radius: 4px;
          padding: 10px 5px 10px 16px;
          box-sizing: border-box;
          transition: all .3s ease-in-out;
        }

        textarea {
          width: 100%;
          font-size: 14px;
          line-height: 18px;
          color: #1B1A1C;
          border: 1px solid #DFE0EB;
          border-radius: 4px;
          padding: 10px 5px 10px 16px;
          box-sizing: border-box;
          resize: none;
          overflow: hidden;
          outline: none;
        }

        .custom-select {
          width: 100%;
          font-size: 14px;
          line-height: 18px;
          color: #1B1A1C;
          border: 1px solid #DFE0EB;
          border-radius: 4px;

          .selected {
            padding: 11px 5px 11px 16px;
            box-sizing: border-box;

            &::after {
              right: 10px;
              top: 18px;
            }
          }

          .items {
            width: 100%;
          }
        }

        &-error {
          input,textarea {
            border: 1px solid #FF603D;
          }

          .balance-available {
            color: #FF603D;
          }
        }
      }
    }

    .error-text {
      position: absolute;
      bottom: -27px;
      left: 0;
      color: #FF603D;
      font-size: 14px;
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 16px;
      border-top: 1px solid #DFE0EB;
      padding: 17px 24px 24px;
      box-sizing: border-box;

      /*@media (max-width: 1079px) {
        position: absolute;
        bottom: 40px;
      }*/

      &-item {
        outline: none;
        width: 100%;
        max-width: 160px;
        border: none;
        padding: 12px 0;
        box-sizing: border-box;
        cursor: pointer;
        text-align: center;
      }

      .cancel {
        background: none;
        color: #0A68F7;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        border-radius: 8px;
        transition: 0.3s ease-in-out;

        &:hover {
          background: rgba(27, 26, 28, 0.03);
        }

        &-orange {
          color: #FF603D;
        }
      }

      .submit {
        background: #0A68F7;
        border-radius: 8px;
        color: white;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;

        &:hover {
          opacity: 0.9;
          transition: 0.3s ease-in-out;
        }

        &-orange {
          background: #FF603D;
        }

        &_disabled {
          opacity: 0.4;

          &:hover {
            opacity: 0.4;
            cursor: default;
          }
        }
      }


    }

  }
}
</style>

