<script>
import {mapGetters} from "vuex";
import OrderService from "@/services/order.service";
import SuccessModal from "@/pages/notification/SuccessModal.vue";
import ErrorModal from "@/pages/notification/ErrorModal.vue";
import TradeLoader from '@/components/trade/components/TradeLoader.vue';
import CopyNotification from '@/pages/notification/CopyNotification.vue';

export default {
  name: "BalanceMirrorWithdraw",
  components: {CopyNotification, TradeLoader},
  data() {
    return {
      sentData: {
        token: '',
        recipientAddress: '',
        amount: 0,
        mfa: ''
      },
      marketUrl: '',
      errorAddress: false,
      errorMfa: false,
      errorAmount: false,
      isConfirmSend: false,
      isValidSent: false,
      statusSent: '',
      hashUrl: '',
      hash: '',
      isSented: false,
      isSentError: false,
      warningMessage: '',
      isSuccessCopy: false,
      errorText: '',
      copyText: '',
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      mirrorWithdrawData: 'getMirrorWithdraw',
      userData: 'getUserProfileData',
      SuccessModal: 'getSuccess',
      ErrorModal: 'getError',
    }),
  },
  watch: {
    'sentData.amount'(newValue) {
      this.validateAmount(newValue);
    },
    'sentData.mfa'(newValue) {
      if (newValue !== '') {
        this.validateMfa(newValue);
      }
    },
    'sentData.recipientAddress'(newValue) {
      this.validateRecipientAddress(newValue);
    }
  },
  mounted() {
    this.adjustHeight()
    if (this.mirrorWithdrawData) {
      this.sentData.token = this.mirrorWithdrawData.token;
      this.sentData.network = this.mirrorWithdrawData.network;
      this.marketUrl = this.mirrorWithdrawData.marketUrl;
    }
  },
  methods: {
    adjustHeight() {
      const textarea = this.$refs.textarea;
      textarea.style.height = '40px';
      textarea.style.height = textarea.scrollHeight > 40 ? textarea.scrollHeight + 'px' : '40px';
    },
    handleClickOutside(event) {
      if (event.target.classList.contains('mirrorWithdraw')) {
        this.closeModal();
      }
    },
    checkValid() {
      this.isValidSent = !this.errorMfa &&
        !this.errorAmount &&
        !this.errorAddress &&
        this.sentData.recipientAddress !== '' &&
        this.sentData.mfa !== '' &&
        this.sentData.mfa.length > 4 &&
        (this.sentData.amount !== 0 &&
          this.sentData.amount !== '0' &&
          this.sentData.amount !== '' &&
          this.sentData.amount <= this.mirrorWithdrawData.balanceAvailable);
    },
    validateAmount(newValue) {
      const stringValue = newValue.toString();
      const numericValue = Number(newValue);
      this.errorAmount = isNaN(numericValue) ||
        stringValue.endsWith('.') ||
        numericValue > this.mirrorWithdrawData.balanceAvailable ||
        numericValue < 0;
      this.checkValid();
    },
    validateMfa(newValue) {
      this.errorMfa = newValue === '' || newValue.length <= 4;
      this.checkValid();
    },
    validateRecipientAddress() {
      let recipientAddress = this.sentData.recipientAddress.trim();
      let regex;

      const hasCyrillic = /[а-яА-ЯЁё]/.test(recipientAddress);

      if (hasCyrillic) {
        this.errorAddress = true;
      } else {
        switch (this.mirrorWithdrawData.network) {
          case 'ETH':
            regex = /^(0x)[0-9A-Fa-f]{40}$/;
            break;
          case 'BTC':
            regex = /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^[(bc1q)|(bc1p)][0-9A-Za-z]{37,62}$/;
            break;
          case 'XMR':
            regex = /^(?:[48][0-9AB]|4[1-9A-HJ-NP-Za-km-z]{12}(?:[1-9A-HJ-NP-Za-km-z]{30})?)[1-9A-HJ-NP-Za-km-z]{93}$/;
            break;
          default:
            regex = /.*/;
            break;
        }

        this.errorAddress = !regex.test(recipientAddress);
      }
      this.checkValid();
    },
    closeModal() {
      this.$store.commit('setMirrorWithdraw', null);
    },
    amountToFixedToken(val, token) {
      const numberToFixed = (token.toUpperCase() === 'BTC' || token.toUpperCase() === 'ETH') ? 4 : 2;
      const stringValue = val.toString();

      if (stringValue.indexOf('.') === -1) {
        return val;
      }

      const truncatedValue = stringValue.slice(0, stringValue.indexOf('.') + numberToFixed + 1);
      return parseFloat(truncatedValue);
    },
    createBalanceSent() {
      if (this.sentData.mfa !== '' && this.sentData.recipientAddress !== '' && (this.sentData.amount !== 0 && this.sentData.amount <= this.mirrorWithdrawData.balanceAvailable)) {
        this.isConfirmSend = true;
        this.warningMessage = `Token <b>` + this.sentData.token + `</b><br> Amount: <b>` + this.sentData.amount + `</b><br> To address: <b>` + this.sentData.recipientAddress

      } else {
        this.errorMfa = this.sentData.mfa === '';
        this.errorAddress = this.sentData.recipientAddress === '';
        this.errorAmount = this.sentData.amount !== 0 || this.sentData.amount <= this.mirrorWithdrawData.balanceAvailable;
      }
    },
    onCopyAddress() {
      this.copyText = 'Copied to clipboard!';

      this.isSuccessCopy = true;

      setTimeout(() => {
        this.isSuccessCopy = false
      }, 2000);
    },
    returnToWithdraw() {
      this.isSented = false;
      this.isSentError = false;
      this.sentData.mfa = ''
      this.errorMfa = false
    },
    confirmSent() {
      this.isLoading = true
      const data = {
        token: this.sentData.token,
        recipientAddress: this.sentData.recipientAddress,
        amount: Number(this.sentData.amount),
        mfaCode: Number(this.sentData.mfa),

      };

      if (this.mirrorWithdrawData.token === 'USDT' || this.mirrorWithdrawData.token === 'USDC') {
        data.network = this.mirrorWithdrawData.network
      }

      this.isValidSent = false;
      this.isConfirmSend = false;

      OrderService.sentMirrorBalance(data).then((res) => {
        if (res) {
          this.isValidSent = false;
          this.isConfirmSend = false;
          this.successPopup(res);
        }
      }).catch(error => {
        this.errorPopup(error.response.data.message);
      })
    },
    successPopup(res) {
      this.hashUrl = this.marketUrl + res.data.result.hash;
      this.hash = res.data.result.hash;
      this.isSented = true;
      this.isLoading = false;

      //const message = `Token <b>` + this.sentData.token + `</b><br> Amount: <b>` + this.sentData.amount + `</b><br> To address: <b>` + this.sentData.recipientAddress + `</b><br>`

      /*this.$store.commit('setSuccess', {
        name: 'Withdrawal sent',
        value: message,
        url: this.hashUrl,
        hash: res.data.result.hash
      });*/
      /*this.sentData = {
        token: '',
        recipientAddress: '',
        amount: 0,
        mfa: ''
      };*/
    },
    errorPopup(res) {
      this.isLoading = false;
      this.isSented = false;
      this.isSentError = true;
      this.isValidSent = false;
      this.isConfirmSend = false;
      this.errorText = res
    },
    copyTextToClipboard(text) {
      navigator.clipboard.writeText(text)
        .then(() => {
          this.onCopyAddress();
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
        });
    }
  }
}
</script>

<template>
  <div
    class="mirrorWithdraw"
    tabindex="0"
    @click.stop="handleClickOutside"
  >
    <!--  first screen  -->
    <div
      v-if="!isConfirmSend && !isSented && !isSentError && !isLoading"
      class="mirrorWithdraw-form"
    >
      <div>
        <div class="mirrorWithdraw-form-head">
          <div class="mirrorWithdraw-form-title">
            Withdraw
          </div>
          <div
            class="mirrorWithdraw-form-close"
            @click="closeModal"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4519_84506)">
                <path
                  d="M18.2987 5.7107C17.9087 5.3207 17.2787 5.3207 16.8887 5.7107L11.9988 10.5907L7.10875 5.7007C6.71875 5.3107 6.08875 5.3107 5.69875 5.7007C5.30875 6.0907 5.30875 6.7207 5.69875 7.1107L10.5888 12.0007L5.69875 16.8907C5.30875 17.2807 5.30875 17.9107 5.69875 18.3007C6.08875 18.6907 6.71875 18.6907 7.10875 18.3007L11.9988 13.4107L16.8887 18.3007C17.2787 18.6907 17.9087 18.6907 18.2987 18.3007C18.6887 17.9107 18.6887 17.2807 18.2987 16.8907L13.4087 12.0007L18.2987 7.1107C18.6787 6.7307 18.6787 6.0907 18.2987 5.7107Z"
                  fill="#9FA2B4"
                />
              </g>
              <defs>
                <clipPath id="clip0_4519_84506">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div class="mirrorWithdraw-form-inputs">
          <div
            class="mirrorWithdraw-form-inputs-item"
            :class="{'mirrorWithdraw-form-inputs-item-error': errorAmount}"
          >
            <div class="desc">
              Amount
            </div>
            <div class="mirrorWithdraw-form-inputs-item_input">
              <input
                v-model="sentData.amount"
                type="text"
              >
              <div class="mirrorWithdraw-form-inputs-item-network">
                <div>
                  {{ mirrorWithdrawData.token }}
                </div>
                <div
                  class="max-value"
                  @click="sentData.amount = amountToFixedToken(mirrorWithdrawData.balanceAvailable,mirrorWithdrawData.token)"
                >
                  Max
                </div>
              </div>
            </div>

            <div class="balance-available">
              Available: {{ amountToFixedToken(mirrorWithdrawData.balanceAvailable,mirrorWithdrawData.token) }} {{ mirrorWithdrawData.token }} ({{ mirrorWithdrawData.network }})
            </div>
          </div>
          <div
            class="mirrorWithdraw-form-inputs-item"
            :class="{'mirrorWithdraw-form-inputs-item-error': errorAddress}"
          >
            <div class="desc">
              Address
            </div>
            <div class="mirrorWithdraw-form-inputs-item_input">
              <textarea
                ref="textarea"
                v-model="sentData.recipientAddress"
                @input="adjustHeight"
              />
            </div>
          </div>
          <div
            class="mirrorWithdraw-form-inputs-item"
            :class="{'mirrorWithdraw-form-inputs-item-error': errorMfa}"
          >
            <div class="desc">
              OTP code
            </div>
            <input
              v-model="sentData.mfa"
              type="text"
            >
          </div>
        </div>
      </div>
      <div class="mirrorWithdraw-form-buttons">
        <div
          class="mirrorWithdraw-form-buttons-item cancel"
          @click="closeModal"
        >
          Cancel
        </div>

        <div
          v-if="!isValidSent"
          class="mirrorWithdraw-form-buttons-item submit submit_disabled"
        >
          Сontinue
        </div>
        <div
          v-if="isValidSent"
          class="mirrorWithdraw-form-buttons-item submit"
          @click="createBalanceSent"
        >
          Сontinue
        </div>
      </div>
    </div>
    <!--  second screen  -->
    <div
      v-if="isConfirmSend && !isSented && !isSentError && !isLoading"
      class="mirrorWithdraw-form mirrorWithdraw-form_warning"
    >
      <div>
        <div class="mirrorWithdraw-form-head">
          <div class="mirrorWithdraw-form-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
            >
              <path
                d="M22 30H26V34H22V30ZM22 14H26V26H22V14ZM23.98 4C12.94 4 4 12.96 4 24C4 35.04 12.94 44 23.98 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 23.98 4ZM24 40C15.16 40 8 32.84 8 24C8 15.16 15.16 8 24 8C32.84 8 40 15.16 40 24C40 32.84 32.84 40 24 40Z"
                fill="#FF783E"
              />
            </svg>
          </div>
          <div
            class="mirrorWithdraw-form-close"
            @click="closeModal"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4519_84506)">
                <path
                  d="M18.2987 5.7107C17.9087 5.3207 17.2787 5.3207 16.8887 5.7107L11.9988 10.5907L7.10875 5.7007C6.71875 5.3107 6.08875 5.3107 5.69875 5.7007C5.30875 6.0907 5.30875 6.7207 5.69875 7.1107L10.5888 12.0007L5.69875 16.8907C5.30875 17.2807 5.30875 17.9107 5.69875 18.3007C6.08875 18.6907 6.71875 18.6907 7.10875 18.3007L11.9988 13.4107L16.8887 18.3007C17.2787 18.6907 17.9087 18.6907 18.2987 18.3007C18.6887 17.9107 18.6887 17.2807 18.2987 16.8907L13.4087 12.0007L18.2987 7.1107C18.6787 6.7307 18.6787 6.0907 18.2987 5.7107Z"
                  fill="#9FA2B4"
                />
              </g>
              <defs>
                <clipPath id="clip0_4519_84506">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div class="mirrorWithdraw-info">
          <div class="title">
            Are you sure?
          </div>
          <div class="description">
            The operation cannot be canceled. <br>
            Any mistake can lead to loss of funds. Before proceeding,
            we recommend that you double-check all information.
          </div>
          <div class="info-row">
            <span>Amount</span>
            <span>{{ `${sentData.amount} ${sentData.token}` }}</span>
          </div>
          <div class="info-row">
            <span>Address</span>
            <span>{{ `${sentData.recipientAddress}` }}</span>
          </div>
        </div>
      </div>
      <div class="mirrorWithdraw-form-buttons">
        <div
          class="mirrorWithdraw-form-buttons-item cancel cancel-orange"
          @click="isConfirmSend = false"
        >
          Back
        </div>
        <div
          class="mirrorWithdraw-form-buttons-item submit submit-orange"
          @click="confirmSent"
        >
          Withdraw
        </div>
      </div>
    </div>
    <!--  loader  -->
    <div
      v-if="isLoading"
      class="mirrorWithdraw-form"
    >
      <trade-loader />
    </div>
    <!--  success screen  -->
    <div
      v-if="isSented && !isLoading"
      class="mirrorWithdraw-form mirrorWithdraw-form_warning"
    >
      <CopyNotification
        v-if="isSuccessCopy"
        :text="copyText"
      />
      <div>
        <div class="mirrorWithdraw-form-head">
          <div class="mirrorWithdraw-form-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
            >
              <path
                d="M24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM24 40C15.18 40 8 32.82 8 24C8 15.18 15.18 8 24 8C32.82 8 40 15.18 40 24C40 32.82 32.82 40 24 40ZM33.18 15.16L20 28.34L14.82 23.18L12 26L20 34L36 18L33.18 15.16Z"
                fill="#0A68F7"
              />
            </svg>
          </div>
          <div
            class="mirrorWithdraw-form-close"
            @click="closeModal"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4519_84506)">
                <path
                  d="M18.2987 5.7107C17.9087 5.3207 17.2787 5.3207 16.8887 5.7107L11.9988 10.5907L7.10875 5.7007C6.71875 5.3107 6.08875 5.3107 5.69875 5.7007C5.30875 6.0907 5.30875 6.7207 5.69875 7.1107L10.5888 12.0007L5.69875 16.8907C5.30875 17.2807 5.30875 17.9107 5.69875 18.3007C6.08875 18.6907 6.71875 18.6907 7.10875 18.3007L11.9988 13.4107L16.8887 18.3007C17.2787 18.6907 17.9087 18.6907 18.2987 18.3007C18.6887 17.9107 18.6887 17.2807 18.2987 16.8907L13.4087 12.0007L18.2987 7.1107C18.6787 6.7307 18.6787 6.0907 18.2987 5.7107Z"
                  fill="#9FA2B4"
                />
              </g>
              <defs>
                <clipPath id="clip0_4519_84506">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div class="mirrorWithdraw-info">
          <div class="title">
            Withdrawal sent
          </div>
          <div class="info-row">
            <span>token</span>
            <span>{{ sentData.token }}</span>
          </div>
          <div class="info-row">
            <span>Amount</span>
            <span>{{ sentData.amount }}</span>
          </div>
          <div class="info-row">
            <span>Address</span>
            <span>{{ `${sentData.recipientAddress}` }}</span>
          </div>
          <div class="info-row">
            <span>Hash</span>
            <span>
              <a
                :href="hashUrl"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ `${hash}` }}
              </a>
            </span>
          </div>
        </div>
      </div>
      <div class="mirrorWithdraw-form-buttons">
        <div
          class="mirrorWithdraw-form-buttons-item cancel"
          @click="copyTextToClipboard(hash)"
        >
          Copy Hash
        </div>
        <div
          class="mirrorWithdraw-form-buttons-item submit"
          @click="closeModal"
        >
          Close
        </div>
      </div>
    </div>
    <!-- unsuccess screen   -->
    <div
      v-if="!isSented && isSentError && !isLoading"
      class="mirrorWithdraw-form mirrorWithdraw-form_warning"
    >
      <div class="error-content-wrapper">
        <div class="mirrorWithdraw-form-head">
          <div class="mirrorWithdraw-form-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
              <g clip-path="url(#clip0_2028_9317)">
                <path d="M24 11.98L39.06 38H8.94L24 11.98ZM24 4L2 42H46L24 4ZM26 32H22V36H26V32ZM26 20H22V28H26V20Z" fill="#FF783E"/>
              </g>
              <defs>
                <clipPath id="clip0_2028_9317">
                  <rect width="48" height="48" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div
            class="mirrorWithdraw-form-close"
            @click="closeModal"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4519_84506)">
                <path
                  d="M18.2987 5.7107C17.9087 5.3207 17.2787 5.3207 16.8887 5.7107L11.9988 10.5907L7.10875 5.7007C6.71875 5.3107 6.08875 5.3107 5.69875 5.7007C5.30875 6.0907 5.30875 6.7207 5.69875 7.1107L10.5888 12.0007L5.69875 16.8907C5.30875 17.2807 5.30875 17.9107 5.69875 18.3007C6.08875 18.6907 6.71875 18.6907 7.10875 18.3007L11.9988 13.4107L16.8887 18.3007C17.2787 18.6907 17.9087 18.6907 18.2987 18.3007C18.6887 17.9107 18.6887 17.2807 18.2987 16.8907L13.4087 12.0007L18.2987 7.1107C18.6787 6.7307 18.6787 6.0907 18.2987 5.7107Z"
                  fill="#9FA2B4"
                />
              </g>
              <defs>
                <clipPath id="clip0_4519_84506">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div class="mirrorWithdraw-info">
          <div class="title">
            Withdrawal Error
          </div>
          <div class="description">
            {{ errorText }}
          </div>
        </div>
      </div>
      <div class="mirrorWithdraw-form-buttons">
        <div
          class="mirrorWithdraw-form-buttons-item cancel cancel-orange"
          @click="closeModal"
        >
          Discard
        </div>
        <div
          class="mirrorWithdraw-form-buttons-item submit submit-orange"
          @click="returnToWithdraw"
        >
          Try Again
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.mirrorWithdraw {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  height: 100vh;
  z-index: 99;
  background: rgba(67, 40, 164, 0.54);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  overflow: auto;
  display: grid;
  place-items: center;

  /*@media (max-width: 1079px) {
    height: 100%;
  }*/

  &-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 6px 24px rgba(27, 26, 28, 0.16);
    max-width: 480px;
    min-height: 509px;
    width: 100%;
    position: relative;
    background: white;
    border-radius: 16px;
    box-sizing: border-box;

    &_warning {

      .mirrorWithdraw-form-head {
        padding-top: 24px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .mirrorWithdraw-form-inputs {
        padding-bottom: 0;

        &-item__text {
          .text {
            text-align: left;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      .error-content-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 419px;
      }

    }

    .mirrorWithdraw-info {
      padding: 0 32px;

      .title {
        margin-bottom: 12px;
        color: #1B1A1C;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
      }

      .description {
        margin-bottom: 32px;
        color: #1B1A1C;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .info-row {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        gap: 6px;

        span:first-child {
          color: #9FA2B4;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }

        span:last-child {
          color: #1B1A1C;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          word-break: break-all;
        }

        a {
          color: var(--buel, #0065FF);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          word-break: break-all;
        }
      }
    }

    .trade-loader {
      height: 509px;
    }

    /*@media (max-width: 1079px) {
      margin: 0;
      height: 100vh;
      padding-bottom: 20px;
      border-radius: 0;
      overflow: auto;
      flex-direction: column;
      box-sizing: border-box;
    }*/

    &-title {
      font-size: 24px;
      line-height: 28px;
      color: #1B1A1C;
      font-weight: 700;
      padding: 24px 24px 32px;
      box-sizing: border-box;
    }

    &-icon {
      display: grid;
      place-items: center;
      margin-bottom: 12px;
    }

    &-close {
      position: absolute;
      right: 16px;
      top: 16px;
      display: grid;
      place-items: center;
      width: 24px;
      height: 24px;
      cursor: pointer;

      svg {
        path {
          transition: all .3s ease-in-out;
        }
      }

      &:hover {
        svg {
          path {
            fill: #0A68F7;
          }
        }
      }
    }

    &-inputs {
      padding: 0 24px 40px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 24px;

      &-item {
        width: 100%;
        position: relative;

        /*@media (max-width: 1079px) {
          max-width: 100%;
        }*/

        &_input {
          position: relative;
        }

        &__text {
          display: flex;
          flex-direction: column;
          align-items: center;

          .title {
            color: #1B1A1C;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
          }

          .text {
            margin-top: 12px;
            color: var(--black, #1B1A1C);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            letter-spacing: 0.2px;
          }
        }

        &-network {
          height: 100%;
          position: absolute;
          z-index: 9;
          right: 16px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 12px;
          color: #9FA2B4;
          text-align: right;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;

          .max-value {
            color: #0A68F7;
            font-weight: 700;

            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }

        .balance-available {
          margin-top: 5px;
          font-size: 12px;
          color: #9FA2B4;
        }


        .desc {
          font-size: 14px;
          line-height: 16px;
          color: #0A68F7;
          margin-bottom: 8px;
        }

        input {
          width: 100%;
          font-size: 14px;
          line-height: 18px;
          color: #1B1A1C;
          border: 1px solid #DFE0EB;
          border-radius: 4px;
          padding: 10px 5px 10px 16px;
          box-sizing: border-box;
          transition: all .3s ease-in-out;
        }

        textarea {
          width: 100%;
          font-size: 14px;
          line-height: 18px;
          color: #1B1A1C;
          border: 1px solid #DFE0EB;
          border-radius: 4px;
          padding: 10px 5px 10px 16px;
          box-sizing: border-box;
          resize: none;
          overflow: hidden;
          outline: none;
        }

        .custom-select {
          width: 100%;
          font-size: 14px;
          line-height: 18px;
          color: #1B1A1C;
          border: 1px solid #DFE0EB;
          border-radius: 4px;

          .selected {
            padding: 11px 5px 11px 16px;
            box-sizing: border-box;

            &::after {
              right: 10px;
              top: 18px;
            }
          }

          .items {
            width: 100%;
          }
        }

        &-error {
          input,textarea {
            border: 1px solid #FF603D;
          }

          .balance-available {
            color: #FF603D;
          }
        }
      }
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 16px;
      border-top: 1px solid #DFE0EB;
      padding: 17px 24px 24px;
      box-sizing: border-box;

      /*@media (max-width: 1079px) {
        position: absolute;
        bottom: 40px;
      }*/

      &-item {
        outline: none;
        width: 100%;
        max-width: 160px;
        border: none;
        padding: 12px 0;
        box-sizing: border-box;
        cursor: pointer;
        text-align: center;
      }

      .cancel {
        background: none;
        color: #0A68F7;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        border-radius: 8px;
        transition: 0.3s ease-in-out;

        &:hover {
          background: rgba(27, 26, 28, 0.03);
        }

        &-orange {
          color: #FF603D;
        }
      }

      .submit {
        background: #0A68F7;
        border-radius: 8px;
        color: white;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;

        &:hover {
          opacity: 0.9;
          transition: 0.3s ease-in-out;
        }

        &-orange {
          background: #FF603D;
        }

        &_disabled {
          opacity: 0.4;

          &:hover {
            opacity: 0.4;
            cursor: default;
          }
        }
      }


    }

  }
}
</style>

