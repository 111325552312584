export const TABLE_TH = [
    {
        name: 'ID',
        sort: '',
        noSort: false,
        key: 'id',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Create date',
        sort: '',
        noSort: false,
        key: 'createDate',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Email',
        sort: '',
        noSort: false,
        key: 'email',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Wallet',
        sort: '',
        noSort: false,
        key: 'wallet',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Name',
        sort: '',
        noSort: false,
        key: 'name',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Discount',
        sort: '',
        noSort: false,
        key: 'discount',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Order',
        sort: '',
        noSort: false,
        key: 'order',
        description: 'Trade round is the latest round of trade negotiations'
    },
    {
        name: 'Status',
        sort: '',
        noSort: false,
        key: 'status',
        description: 'Trade round is the latest round of trade negotiations'
    },
];
