<template>
  <AccountLayoutNew>
    <template #title>
      <div class="layout-header">
        <h2>
          <ExchangesIcon />
          Exchanges
        </h2>
      </div>
    </template>
    <template #content>
      <div
        v-if="exchangesData"
        class="main-block exchangesListNew"
      >
        <div class="table-block" ref="tableBlockRef">
          <div
            ref="stickyBlock"
            class="table-block-header-exchange"
            :class="{ stickyHeader: isSticky }"
          >
            <div>
              <div class="filters-component-container">
                <div class="filters-component-left-side">
                  <Search
                    @searchHandler="searchHandler"
                  />
                  <StatusFilter
                    :is-status-search="isStatusSearch"
                    :search-status-toggle="searchStatusToggle"
                    :status-handler="statusHandler"
                    :selected-status="selectedStatus"
                    @handleSearchStatusClickOutside="() => isStatusSearch = false"
                  />
                  <MarketFilter
                    :is-market-search="isMarketSearch"
                    :market-search-toggle="marketSearchToggle"
                    :market-search-handler="marketSearchHandler"
                    :selected-market="selectedMarket"
                    @handleMarketSearchClickOutside="() => isMarketSearch = false"
                  />
                  <PartnerFilter
                    :is-partner-search="isPartnerSearch"
                    :partner-search-toggle="partnerSearchToggle"
                    :partner-search-handler="partnerSearchHandler"
                    :selected-partner="selectedPartner"
                    @handlePartnerSearchClickOutside="() => isPartnerSearch = false"
                  />
                  <AdminFilter
                    :is-admin-search="isAdminSearch"
                    :admin-search-toggle="adminSearchToggle"
                    :admin-search-handler="adminSearchHandler"
                    :selected-admin="selectedAdmin"
                    @handleAdminSearchClickOutside="() => isAdminSearch = false"
                  />
                  <DatesFilter
                    :is-dates-open="isDatesOpen"
                    @dateHandler="dateHandler"
                  />
                  <div
                    v-if="clientIdParam || selectedSearchInput || selectedStatus || selectedAdmin || selectedMarket || selectedPartner || selectedDate"
                    class="selected-filter-container"
                  >
                    <div
                      v-if="clientIdParam"
                      class="selected-filter-item"
                    >
                      <span>Client Id:</span>
                      <span>{{ clientIdParam }}</span>
                      <FilterClose @click="clientIdHandler()" />
                    </div>

                    <div
                      v-if="selectedSearchInput"
                      class="selected-filter-item"
                    >
                      <span>Search:</span>
                      <span>{{ selectedSearchInput }}</span>
                      <FilterClose @click="searchHandler(null)" />
                    </div>

                    <div
                      v-if="selectedStatus"
                      class="selected-filter-item"
                    >
                      <span>Status:</span>
                      <span>{{ selectedStatus.name }}</span>
                      <FilterClose @click="statusHandler(null)" />
                    </div>

                    <div
                      v-if="selectedMarket"
                      class="selected-filter-item"
                    >
                      <span>Market:</span>
                      <span>{{ selectedMarket }}</span>
                      <FilterClose @click="marketSearchHandler(null)" />
                    </div>

                    <div
                      v-if="selectedPartner"
                      class="selected-filter-item"
                    >
                      <span>Partner:</span>
                      <span>{{ selectedPartner.login }}</span>
                      <FilterClose @click="partnerSearchHandler(null)" />
                    </div>

                    <div
                      v-if="selectedAdmin"
                      class="selected-filter-item"
                    >
                      <span>Admin:</span>
                      <span>{{ selectedAdmin.login }}</span>
                      <FilterClose @click="adminSearchHandler(null)" />
                    </div>

                    <div
                      v-if="selectedDate && selectedDate.length"
                      class="selected-filter-item"
                    >
                      <span>Date:</span>
                      <span v-if="selectedDate[0].split('T')[0] === selectedDate[1].split('T')[0]">
                        {{ selectedDate[0].split('T')[0] }}
                      </span>
                      <span v-if="selectedDate[0].split('T')[0] !== selectedDate[1].split('T')[0]">
                        {{ selectedDate[0].split('T')[0] }} - {{ selectedDate[1].split('T')[0] }}
                      </span>
                      <FilterClose @click="dateHandler(null)" />
                    </div>
                  </div>
                </div>
                <Timer
                  :handle-timer-refresh="timerRefresh"
                  :handle-select-timer-value="handleSelectTimerValue"
                  :timer-handler="timerHandler"
                  :timer-handle="timerHandle"
                  :selected-timer="selectedTimer"
                  :timer-value="timerValue"
                  @click-outside="handleClickOutside"
                />
              </div>
            </div>
            <table class="sticky-table-head">
              <thead>
                <tr>
                  <th
                    v-for="(item, index) in tableTh"
                    :id="item.id"
                    :key="index"
                    :class="'table-' + item.key"
                  >
                    <div
                      class="text"
                      @click="sortData(item)"
                      @mouseover="tokenTo = index"
                      @mouseleave="tokenTo = null"
                    >
                      {{ item.name }}
                      <span v-if="item.isSortable">
                        <sort-a-z v-if="item.sort === 'ASC' && currentSortRow === item.key" />
                        <sort-z-a v-if="item.sort === 'DESC' && currentSortRow === item.key" />
                        <sort-passive v-if="currentSortRow !== item.key" />
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div
            id="tableBlock"
            class="table-block-table"
            :class="[
              { 'without-height': isLoading || exchangesData.length === 0 },
              { 'sticky': isSticky }
            ]"
          >
            <Transition name="committee">
              <table class="table">
                <thead>
                  <tr class="table-headers">
                    <th
                      v-for="(item, index) in tableTh"
                      :id="item.id"
                      :key="index"
                      scope="col"
                      :class="'table-' + item.key"
                    >
                      <div
                        class="text"
                        @click="sortData(item)"
                        @mouseover="tokenTo = index"
                        @mouseleave="tokenTo = null"
                      >
                        {{ item.name }}
                        <span v-if="item.isSortable">
                          <sort-a-z v-if="item.sort === 'ASC' && currentSortRow === item.key" />
                          <sort-z-a v-if="item.sort === 'DESC' && currentSortRow === item.key" />
                          <sort-passive v-if="currentSortRow !== item.key" />
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-if="!isLoading"
                  :key="refreshPageKey"
                >
                  <ExchangesListItem
                    v-for="(exchange) in exchangesData"
                    :key="exchange.id"
                    :item="exchange"
                    :is-open="openDropdownId === exchange.id"
                    @toggle-dropdown="handleToggleDropdown"
                    @close-dropdown="handleCloseDropdown"
                  />
                </tbody>
              </table>
            </Transition>
          </div>
          <paged-pagination
            v-if="exchangesData?.length && !isLoading && Exchanges.maxPage > 1"
            :total="Exchanges.maxPage"
            :current="page"
            @change-page="pageHandler"
            @prev-page="pageHandler"
            @next-page="pageHandler"
          />
        </div>
        <trade-loader v-if="isLoading" />
        <TradeNoData v-if="!exchangesData?.length && !isLoading" />
      </div>
      <transition name="committee">
        <CopyNotification
          v-if="isUpdate"
          :text="updateText"
        />
      </transition>
    </template>
  </AccountLayoutNew>
</template>

<script>
import {defineComponent} from 'vue';
import ExchangesIcon from '@/assets/img/icons/menu/exchangesIcon.vue';
import AccountLayoutNew from '@/components/admin/AccountLayoutNew.vue';
import Loading from 'vue-loading-overlay';
import SortAZ from '@/icons/sorting/sort-a-z.vue';
import SortPassive from '@/icons/sorting/sort-passive.vue';
import SortZA from '@/icons/sorting/sort-z-a.vue';
import {STATUSES, TABLE_TH} from '@/pages/exchanges-2/exchangeData';
import Timer from '@/components/admin/Timer.vue';
import Search from '@/components/admin/Search.vue';
import StatusFilter from '@/components/admin/StatusFilter.vue';
import MarketFilter from '@/components/admin/MarketFilter.vue';
import PartnerFilter from '@/components/admin/PartnerFilter.vue';
import AdminFilter from '@/components/admin/AdminFilter.vue';
import DatesFilter from '@/components/admin/DatesFilter.vue';
import ExchangesListItem from '@/pages/exchanges-2/partials/ExchangesListItem.vue';
import FilterClose from '@/icons/filterClose.vue';
import {mapGetters} from 'vuex';
import filterMixin from '@/mixins/filter.mixin';
import helperMixin from '@/mixins/helper.mixin';
import UserService from '@/services/user.service';
import PagedPagination from '@/components/admin/PagedPagiantion.vue';
import {PerfectScrollbar} from 'vue3-perfect-scrollbar';
import TradeLoader from '@/components/trade/components/TradeLoader.vue';
import TradeNoData from '@/components/trade/components/TradeNoData.vue';
import CopyNotification from '@/pages/notification/CopyNotification.vue';

export default defineComponent({
  components: {
    CopyNotification,
    TradeLoader,
    TradeNoData,
    ExchangesIcon,
    PagedPagination,
    FilterClose,
    ExchangesListItem,
    DatesFilter,
    AdminFilter,
    PartnerFilter,
    MarketFilter,
    StatusFilter, Search, Timer, SortZA, SortPassive, SortAZ, AccountLayoutNew},
  mixins: [
    filterMixin,
    helperMixin
  ],
  data() {
    return {
      exchangesData: [],
      tableTh: TABLE_TH,
      isLoading: true,
      page: 1,
      tokenTo: false,
      isUpdate: false,
      updateText: '',
      currentSortRow: null,
      openDropdownId: null,
      refreshPageKey: 1,
      // timer
      selectedTimer: {name: "Off", interval: null},
      timerValue: 0,
      timerHandle: false,
      timerId: null,
      // search input
      selectedSearchInput: '',
      // status filter
      isStatusSearch: false,
      selectedStatus: null,
      clientIdParam: null,
      ExchangeStatusList: STATUSES,
      // market filter
      isMarketSearch: false,
      selectedMarket: null,
      // partner filter
      isPartnerSearch: false,
      selectedPartner: null,
      // admin filter
      isAdminSearch: false,
      selectedAdmin: null,
      // date picker
      isDatesOpen: true,
      selectedDate: null,
      // sticky header
      isSticky: false,
    }
  },
  computed: {
    ...mapGetters({
      //ExchangeData: 'getExchangeData',
    }),
    Exchanges: {
      get() {
        return this.$store.state.exchangesV2List;
      },
      set(value) {
        this.$store.commit('setExchangesList', value);
      }
    },
  },
  mounted() {
    this.mountList()
    // sticky header
    window.addEventListener('scroll', this.handleStickyScroll);
  },
  beforeUnmount() {
    clearInterval(this.timerId);
    clearInterval(this.interval);
    window.removeEventListener('scroll', this.handleStickyScroll);
  },
  methods: {
    handleToggleDropdown(itemId) {
      if (this.openDropdownId === itemId) {
        this.openDropdownId = null;
      } else {
        this.openDropdownId = itemId;
      }
    },
    handleCloseDropdown() {
      this.openDropdownId = null;
    },
    mountList() {
      const query = this.$route.query;

      if (query.text) {
        this.selectedSearchInput = query.text
      }

      if (query.clientId) {
        this.clientIdParam = query.clientId
      }

      if (query.status) {
        const statusName = this.ExchangeStatusList.find(statusArrItem => statusArrItem.status === +query.status).name
        this.selectedStatus = {status: query.status,name: statusName }
      }

      if (query.market) {
        this.selectedMarket = query.market
      }

      if (query.partner) {
        UserService.getUserFavorite().then((res) => {
          if (res.status === 200) {
            this.selectedPartner = res.data.result.find(item => item.id === +query.partner);
          }
        })
      }

      if (query.admin) {
        UserService.getAdmins().then((res) => {
          if (res.status === 200) {
            this.selectedAdmin = res.data.result.find(item => item.userId === +query.admin);
          }
        })
      }

      if (query.orderMode) {
        this.toggleSort({
          key: this.$route.query.orderColumn,
          sort: this.$route.query.orderMode === 'ASC' ? 'DESC' : 'ASC',
        });
      }

      if (query.start) {
        this.selectedDate = [query.start, query.end];
      }

      if (query.page) {
        this.page = +query.page;
      }
      this.getListByQuery(query);
    },
    getListByQuery(query) {
      this.$router.push({query: query}).then(() => {
        this.getList(query);
      });
    },
    getList(query) {
      this.endProgress();
      this.$store.dispatch('getExchangesList', query).then(() => {
        this.exchangesData = this.Exchanges.result;
        this.isLoading = false;
        if (this.selectedTimer.interval) {
          this.timerValue = 0;
          this.startProgress()
        }
      });

    },
    // sticky header
    handleStickyScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      this.isSticky = scrollTop >= 105 && window.innerWidth > 819
    },
    // clientId param
    clientIdHandler() {
      this.isLoading = true
      this.page = 1

      let query = {...this.$route.query, page: 1};
      delete query.clientId;
      this.clientIdParam = null

      this.$router.push({
        name: 'Exchanges',
        query: query
      }).then(() => {
        this.getList(query);
      });
    },
    // search input
    searchHandler(inputText) {
      this.selectedSearchInput = inputText
      this.isLoading = true
      this.page = 1

      let query = {...this.$route.query, page: 1, text: inputText};

      if (inputText === null) {
        delete query.text;
      }

      this.$router.push({
        name: 'Exchanges',
        query: query
      }).then(() => {
        this.getList(query);
      });
    },
    // market search
    marketSearchToggle() {
      this.isMarketSearch = !this.isMarketSearch;
    },
    marketSearchHandler(market) {
      this.selectedMarket = market
      this.isMarketSearch = false;
      this.isLoading = true
      this.page = 1

      let query = {...this.$route.query, page: 1, market: market};

      if (market === null) {
        delete query.market;
      }

      this.$router.push({
        name: 'Exchanges',
        query: query
      }).then(() => {
        this.getList(query);
      });
    },
    // partner search
    partnerSearchToggle() {
      this.isPartnerSearch = !this.isPartnerSearch;
    },
    partnerSearchHandler(partner) {
      this.selectedPartner = partner
      this.isPartnerSearch = false;
      this.isLoading = true
      this.page = 1

      let query = {...this.$route.query, page: 1, partner: partner?.id};

      if (partner === null) {
        delete query.partner;
      }

      this.$router.push({
        name: 'Exchanges',
        query: query
      }).then(() => {
        this.getList(query);
      });
    },
    // admin search
    adminSearchToggle() {
      this.isAdminSearch = !this.isAdminSearch;
    },
    adminSearchHandler(admin) {
      this.selectedAdmin = admin
      this.isAdminSearch = false;
      this.isLoading = true
      this.page = 1

      let query = {...this.$route.query, page: 1, admin: admin?.userId};

      if (admin === null) {
        delete query.admin;
      }

      this.$router.push({
        name: 'Exchanges',
        query: query
      }).then(() => {
        this.getList(query);
      });
    },
    // status search
    searchStatusToggle() {
      this.isStatusSearch = !this.isStatusSearch;
    },
    statusHandler(status) {
      this.selectedStatus = status
      this.isStatusSearch = false;
      this.isLoading = true
      this.page = 1

      let query = {...this.$route.query, page: 1, status: status?.status};

      if (status === null) {
        delete query.status;
      }

      this.$router.push({
        name: 'Exchanges',
        query: query
      }).then(() => {
        this.getList(query);
      });
    },
    // date picker
    dateHandler(date) {
      this.selectedDate = date
      this.isLoading = true
      this.page = 1
      let query

      if (date === null) {
        query = {...this.$route.query, page: 1};
        delete query.start;
        delete query.end;
      } else {
        query = {...this.$route.query, page: 1, start: this.selectedDate[0], end: this.selectedDate[1]};
      }
      console.log(query)
      this.$router.push({
        name: 'Exchanges',
        query: query
      }).then(() => {
        this.getList(query);
      });
    },
    // timer
    endProgress() {
      clearInterval(this.interval);
      this.interval = null;
    },
    handleClickOutside() {
      this.timerHandle = false;
    },
    startProgress() {
      this.timerValue = 0;
      this.interval = setInterval(() => {
        this.timerValue += (100 / (this.selectedTimer.interval / 1000));
        if (this.timerValue >= 100) {
          this.timerValue = 0;
          clearInterval(this.interval);
        }
      }, 1000);
    },
    onUpdateHandler() {
      this.updateText = 'Pages refresh';

      this.isUpdate = true;

      setTimeout(() => {
        this.isUpdate = false
      }, 2000);
    },
    timerRefresh() {
      if (this.$route.name !== 'Exchanges' || this.$store.state.shouldTimerStopped) {
        this.endProgress();
        this.startProgress()
        return false
      }
      this.endProgress();

      this.$store.dispatch('getExchangesList', this.$route.query).then(() => {
        this.exchangesData = this.Exchanges.result;
        this.refreshPageKey = Date.now();
        if (this.selectedTimer.interval) {
          this.timerValue = 0;
          this.startProgress()
        }
        this.onUpdateHandler();
      });
    },
    handleSelectTimerValue(item) {
      this.selectedTimer = item;
      clearInterval(this.timerId);

      if (item.interval) {
        this.startProgress();
        this.timerId = setInterval(this.timerRefresh, item.interval);
      } else {
        this.timerValue = 0
        this.endProgress();
      }
      this.timerHandler();
    },
    timerHandler() {
      this.timerHandle = !this.timerHandle
    },
    // pagination
    pageHandler(page) {
      this.isLoading = true;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      this.page = page;

      const query = {...this.$route.query, page: page};

      this.getListByQuery(query);
    },
    // sort
    sortData(type) {
      switch (type.key) {
        case 'id':
        case 'date':
        case 'status':
        case 'partner':
          this.isLoading = true
          this.toggleSort(type);

          let query = {...this.$route.query, page: this.page, orderMode: type.sort, orderColumn: type.key};

          this.$router.push({
            name: 'Exchanges',
            query: query
          }).then(() => {
            this.getList(query);
          });
          break;

        default:
          break;
      }

      this.exchangesData = this.Exchanges.result;
    },
    toggleSort(currentSort = {}) {
      this.tableTh = this.tableTh.map((item) => {
        if (currentSort.key === item.key) {
          this.currentSortRow = item.key
          item.sort = currentSort.sort === 'ASC' ? 'DESC' : 'ASC';
        }

        return item;
      });
    },
  }
})
</script>

<style lang="scss">
@import "../../assets/styles/pages/exchanges-2/exchanges";
@import "../../assets/styles/components/filters";

.exchangesListNew {

  @media (max-width: 1079px) {
    margin-top: 16px;
    min-height: 100vh;
    background: white;
    overflow: auto;
    border-radius: 16px 16px 0 0;
  }
}

/*.exchangesListNew .table-block > .ps {
  overflow: initial !important;
  // fix for a scrollbar plugin
  @media (max-width: 819px) {
    overflow: hidden !important;
    overflow-x: auto !important;
  }
}*/

.exchangesListNew.withFilter .table-block-table {
  @media (max-width: 819px) {
    /*overflow: auto;
    max-height: calc(100vh - 268px);
    height: auto;*/
  }
}

.exchangesListNew .table-block-table.sticky {
  padding-top: 88px;
  @media (max-width: 1252px) {
    padding-top: 78px;
    @media (max-width: 1172px) {
      padding-top: 140px;
    }
  }
}

.exchangesListNew .table-block-table.without-height {
  height: auto;
  overflow: hidden;
}

.exchangesListNew {
  .trade-nodata {
    height: 45vh;
  }

  .trade-loader {
    height: 45vh;
  }
  .table-block-table {

    @media (max-width: 819px) {
      overflow: auto;
      height: 100vh;
    }

    .table {
      @media (max-width: 1079px) {
        width: 100%;

        @media (max-width: 819px) {
          width: 819px;
        }
      }
    }
  }

  .table-block-header-exchange {

    .sticky-table-head {
      display: none;
    }
  }

  .table-block-header-exchange > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 28px 32px 28px;
    gap: 20px;
    @media (max-width: 1290px) {
      flex-direction: column !important;

      @media (max-width: 1079px) {
        padding: 16px 12px;
        gap: 16px 12px;
      }
    }
  }

  .table-block-header-exchange.stickyHeader {
    position: fixed;
    padding-bottom: 0;
    top: 0;
    background: #fff;
    width: calc(100% - 322px);
    z-index: 10;
    border-bottom: 1px solid #EAEFF4;


    @media (max-width: 1079px) {
      left: 0;
      top: 72px;
      width: calc(100%);
    }

    .sticky-table-head {
      display: table;
      width: 100%;
      background: #fff;

      thead {
        border: none;
      }

      th {
        text-align: left;
        vertical-align: baseline;
        padding-bottom: 8px;
      }

      th div {
        position: relative;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: $gray-color;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }

      th.table-run-bot {
        padding-right: 16px;
        padding-left: 32px;
        width: 12%;

        @media (max-width: 1079px) {
          padding-left: 12px;
          padding-right: 12px;
          @media (max-width: 819px) {
            width: 8%;
          }
        }
      }

      th.table-date {
        padding-right: 16px;
        width: 13.5%;

        @media (max-width: 1079px) {
          padding-right: 12px;
        }
      }

      th.table-id {
        padding-right: 16px;
        width: 15%;

        @media (max-width: 1079px) {
          padding-right: 12px;
        }
      }

      th.table-partner {
        padding-right: 16px;
        width: 14%;

        @media (max-width: 1079px) {
          padding-right: 12px;
        }
      }

      th.table-pair {
        padding-right: 16px;
        width: 16%;

        @media (max-width: 1079px) {
          padding-right: 12px;
        }
      }

      th.table-amount {
        padding-right: 16px;
        width: 18%;

        @media (max-width: 1079px) {
          padding-right: 12px;
        }
      }

      th.table-aml {
        padding-right: 16px;
        width: 5.7%;

        @media (max-width: 1079px) {
          padding-right: 12px;
        }
      }

      th.table-status {
        padding-right: 32px;
        width: 5.8%;
        min-width: 88px;

        @media (max-width: 1079px) {
          padding-right: 12px;
          min-width: 64px;
        }
      }
    }
  }

  .table-block-header-title {
    display: flex;
    align-items: center;
    align-self: flex-start;
    height: 32px;
    min-width: fit-content !important;
  }
}
</style>
