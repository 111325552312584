<template>
  <div class="rating_list">
    <div class="rating_list-block">
      <div class="rating_list-block-title">
        <div class="title">
          Withdrawal top
        </div>
        <div class="dates">
          <div
            v-for="(item, index) in datesWithdrawal"
            :key="index"
            :class="{'dates-item': item.key !== 'range', 'dates_range': item.key === 'range', active: item.status === true && item.key !== 'range', 'dates_range_active': item.status === true && item.key === 'range' }"
            @click="filterDate(item)"
          >
            <date-picker
              v-if="item.key === 'range'"
              v-model:value="date"
              :clearable="false"
              :editable="false"
              default-panel="day"
              separator=" - "
              value-type="format"
              :format="customDate"
              placeholder="Custom date"
              :month-format="'MMMM'"
              range
              input-class="dates-item"
              :class="{'dates_filled': date && item.key === 'range'}"
              @change="getStats"
            >
              {{ dateText }}
              <template #icon-calendar>
                <calendar-icon />
              </template>
            </date-picker>
            <span v-if="item.key !== 'range'">
              {{ item.name }}
            </span>
          </div>
        </div>
      </div>
      <div class="rating_list-block-table">
        <table
          v-if="withdrawalTop.length !== 0"
          class="table"
        >
          <thead>
            <tr class="table-headers">
              <th
                v-for="(item, index) in tableTh"
                :key="index"
                scope="col"
                class="text"
                :class="{
                  'table-cell-img': item.key === 'coin',
                  'table-cell-operations': item.key === 'count',
                  'text-total': item.key === 'amount' || item.key === 'amountBtc',
                }"
              >
                <div
                  class="table-headers__item"
                  @click="sortData(item)"
                >
                  {{ item.name }}
                  <sort-passive v-if="!item.sort" />
                  <sort-a-z v-if="item.sort === 'asc'" />
                  <sort-z-a v-if="item.sort === 'desc'" />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(coin, index) in withdrawalTop"
              :key="index"
              class="table-row"
            >
              <td class="table-cell table-cell-img">
                <div class="coin_img">
                  <img
                    v-if="coin.coinImage"
                    :src="domain + coin.coinImage"
                    alt=""
                  >
                  <div
                    v-if="!coin.coinImage"
                    class="coin_img--icon"
                  />
                  <div class="table-cell-name">
                    {{ coin.coin }}
                  </div>
                </div>
              </td>
              <td class="table-cell table-cell-operations">
                {{ coin.count }}
              </td>
              <td class="table-cell table-cell-total">
                {{ coin.amount }}
              </td>
              <td class="table-cell table-cell-total">
                {{ numbersFormatting(coin.amountBtc) }}
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-if="withdrawalTop.length === 0"
          class="chart_empty"
        >
          No data
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderService from "@/services/order.service";
import {DEPOSIT_TABLE_TH, WITHDRAWAL_TABLE_TH} from "@/pages/dashboard/dashboardData";
import moment from "moment";
import {CHART_DATA, FILTER_DATA_TOP} from "@/pages/dashboard/chartData";
import {MAIN_DOMAIN} from "@/store/config";
import SortPassive from "@/icons/sorting/sort-passive";
import SortZA from "@/icons/sorting/sort-z-a";
import SortAZ from "@/icons/sorting/sort-a-z";
import {WITHDRAWAL_TOP_TABLE_TH} from "@/pages/dashboard/partials/withdrawalTop.js";
import CalendarIcon from "@/icons/calendarIcon.vue";
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';

export default {
  name: "WithdrawalTop",
  components: {CalendarIcon, SortAZ, SortZA, SortPassive, DatePicker},
  props: {
    market: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      datesWithdrawal: WITHDRAWAL_TABLE_TH,
      moment: moment,
      dateFormat: 'YYYY-MM-DD',
      currentSorting: 'coin',
      chartOptions: CHART_DATA,
      filterData: FILTER_DATA_TOP,
      domain: MAIN_DOMAIN,
      customDate: 'DD.MM.YYYY',
      withdrawalTop: [],
      tableTh: WITHDRAWAL_TOP_TABLE_TH,
      startDate: null,
      endDate: null,
      date: '',
      formattedDate: ''
    }
  },
  computed: {
    dateText() {
      if (this.date) {
        return this.date;
      } else {
        return "Custom date";
      }
    },
  },
  watch: {
    market() {
      this.datesWithdrawal = this.datesWithdrawal.map((item) => {
        item.status = item.key === 'month';

        return item;
      });
      this.generateTopWithdrawalData();
    },
  },
  mounted() {
    this.datesWithdrawal = this.datesWithdrawal.map((item) => {
      item.status = item.key === 'month';

      return item;
    });
    this.generateTopWithdrawalData();
  },
  methods: {
    generateTopWithdrawalData(startDate = this.filterData.month) {
      this.startDate = this.moment().startOf(startDate).format(this.dateFormat);
      this.endDate = this.moment().format(this.dateFormat);

      if (!this.market) {
        OrderService.topWithdrawal('/?start=' + this.startDate + '&end=' + this.endDate).then((res) => {
          this.datesWithdrawal = WITHDRAWAL_TABLE_TH;
          this.withdrawalTop = res.data.result;
        });
      } else {
        OrderService.topWithdrawal('/?filterExchange=' + this.market + '&orderColumn=amountBtc&orderMode=DESC' + '&start=' + this.startDate + '&end=' + this.endDate).then((res) => {
          this.datesWithdrawal = WITHDRAWAL_TABLE_TH;
          this.withdrawalTop = res.data.result;
        });
      }
    },
    getStats() {
      this.startDate = this.moment(this.date[0], 'DD.MM.YYYY').format('YYYY-MM-DD');
      this.endDate = this.moment(this.date[1], 'DD.MM.YYYY').format('YYYY-MM-DD');

      if (!this.market) {
        OrderService.topWithdrawal('/?start=' + this.startDate + '&end=' + this.endDate).then((res) => {
          this.datesWithdrawal = WITHDRAWAL_TABLE_TH;
          this.withdrawalTop = res.data.result;
        });
      } else {
        OrderService.topWithdrawal('/?filterExchange=' + this.market + '&start=' + this.startDate + '&end=' + this.endDate).then((res) => {
          this.datesWithdrawal = WITHDRAWAL_TABLE_TH;
          this.withdrawalTop = res.data.result;
        });
      }
    },
    numbersFormatting(data) {
      return data.toFixed(8);
    },
    filterDate(item) {
      this.datesWithdrawal = this.datesWithdrawal.map((el) => {
        el.status = item.key === el.key;
        return el;
      });

      this.generateTopWithdrawalData(this.getFilterDate(item));
    },
    getFilterDate(item) {
      let result = 'year';

      switch (item.key) {
        case 'today':
          result = this.filterData.day;
          break;
        case 'week':
          result = this.filterData.week
          break;
        case 'month':
          result = this.filterData.month
          break;
        case 'total':
          result = this.filterData.year
          break;
      }

      return result;
    },
    sortData(type) {
      this.tableTh = this.tableTh.map((item) => {
        if (item.key === type.key) {
          item.sort = type.sort === 'asc' ? 'desc' : 'asc';
        } else {
          item.sort = '';
        }

        return item;
      });

      if (type.key === 'coin') {
        this.withdrawalTop = this.withdrawalTop.sort((a, b) => {
          const aCount = a.coin.localeCompare(b.coin);
          const bCount = b.coin.localeCompare(a.coin);

          return type.sort === 'asc' ? aCount : bCount;
        });
        return
      }

      OrderService.topWithdrawal('/?start='+ this.startDate +'&end='+ this.endDate + '&orderColumn='+ type.key + '&orderMode='+ type.sort.toUpperCase()).then((res) => {
        this.withdrawalTop = res.data.result;
      });
    }
  }
}
</script>

<style lang="scss">
    @import "../../../assets/styles/pages/dashboard/top-list";
</style>
