export const API_URL = 'https://api.swapuz.com';
export const MAIN_DOMAIN = 'https://swapuz.com';
export const FP_API_URL = 'https://api.fpjs.io'
export const FP_API_KEY = 'sbaUVew9TFrlAUIlnxqM'

export const allowedStatusesForRefund
  = ['on pause','segmented','checking limit','on hold','stopped','service on hold', 'wrong network']

export const allowedStatusesForRestart
  = ['received','checking AML']
