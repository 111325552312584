<template>
  <AccountLayout class="dashboard_page">
    <template #title>
      <div class="layout-header">
        <h2>Dashboard <span class="dashboard-market">{{ activeMarket }}</span></h2>
        <div class="layout-header_tabs">
          <div class="layout-header_tabs">
            <div
              :class="{'layout-header_tabs-item--active': activeMarket === 'mirror'}"
              class="layout-header_tabs-item"
              @click="activeMarket = 'mirror'"
            >
              Mirror
            </div>
            <div
              :class="{'layout-header_tabs-item--active': activeMarket === 'whitebit'}"
              class="layout-header_tabs-item "
              @click="activeMarket = 'whitebit'"
            >
              WhiteBit
            </div>
            <div
              :class="{'layout-header_tabs-item--active': activeMarket === 'kucoin'}"
              class="layout-header_tabs-item "
              @click="activeMarket = 'kucoin'"
            >
              Kucoin
            </div>
            <div
              :class="{'layout-header_tabs-item--active': activeMarket === 'gateio'}"
              class="layout-header_tabs-item "
              @click="activeMarket = 'gateio'"
            >
              Gateio
            </div>
            <div
              :class="{'layout-header_tabs-item--active': activeMarket === 'bybit'}"
              class="layout-header_tabs-item "
              @click="activeMarket = 'bybit'"
            >
              ByBit
            </div>
          </div>
        </div>
      </div>
    </template>
    <template
      #content
      class="content_dashboard"
    >
      <div class="dashboard">
        <TransactionsValue :market="activeMarket" />
        <div class="dashboard-rating">
          <DepositTop :market="activeMarket" />
          <WithdrawalTop :market="activeMarket" />
        </div>
      </div>
    </template>
  </AccountLayout>
</template>

<script>
import AccountLayout from "../../components/admin/AccountLayout.vue";
import { API_URL } from "@/store/config";
import DepositTop from "@/pages/dashboard/partials/DepositTop.vue";
import WithdrawalTop from "@/pages/dashboard/partials/WithdrawalTop.vue";
import TransactionsValue from "@/pages/dashboard/partials/TransactionsValue.vue";

export default {
  name: "DashboardMarket",
  components: {TransactionsValue, WithdrawalTop, DepositTop, AccountLayout},
  data() {
    return {
      API_URL: API_URL,
      activeMarket: 'mirror'
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/layout/dashboard';
</style>
