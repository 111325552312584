<template>
  <div class="transactions_value">
    <div class="transactions_value-block">
      <main-chart-update v-if="!market" />
      <Chart
        v-else
        :market="market"
      />
      <div class="transactions_value-chart transactions_value-chart-info">
        <div class="transactions_value-chart-title-block">
          <div class="title">
            Quick Stat
          </div>
        </div>
        <div class="transactions_value-chart-info-table">
          <table
            v-if="statsData"
            class="table"
          >
            <thead>
              <tr class="table-headers">
                <th>
                  <div class="text table-cell-title">
                    Start
                  </div>
                </th>
                <th>
                  <date-picker
                    v-model:value="customTodayDate"
                    :clearable="false"
                    :editable="false"
                    default-panel="day"
                    separator=" - "
                    placeholder="Today"
                    :format="dateFormat"
                    :month-format="'MMMM'"
                    range
                    input-class="dates-item"
                    :class="{'dates_filled': customTodayDate}"
                    @change="getCustomStats(customTodayDate, 'today')"
                  >
                    <template #input>
                      <div
                        v-if="customTodayDate"
                        class="date-picker_input"
                      >
                        <div>{{ dateFormatPicker(customTodayDate[0]) }}</div>
                        <div v-if="dateFormatPicker(customTodayDate[1]) !== dateFormatPicker(customTodayDate[0])">
                          - {{ dateFormatPicker(customTodayDate[1]) }}
                        </div>
                      </div>
                      <div
                        v-else
                        class="date-picker_input"
                      >
                        Today
                      </div>
                    </template>
                    <template #icon-calendar>
                      <calendar-picker v-if="!customTodayDate" />
                      <calendar-picked v-else />
                    </template>
                  </date-picker>
                </th>
                <th>
                  <date-picker
                    v-model:value="customYesterdayDate"
                    :clearable="false"
                    :editable="false"
                    default-panel="day"
                    separator=" - "
                    :format="dateFormat"
                    placeholder="Yesterday"
                    :month-format="'MMMM'"
                    range
                    input-class="date-picker_input"
                    :class="{'dates_filled': customYesterdayDate}"
                    @change="getCustomStats(customYesterdayDate, 'yesterday')"
                  >
                    <template #input>
                      <div
                        v-if="customYesterdayDate"
                        class="date-picker_input"
                      >
                        <div>{{ dateFormatPicker(customYesterdayDate[0]) }}</div>
                        <div v-if="dateFormatPicker(customYesterdayDate[1]) !== dateFormatPicker(customYesterdayDate[0])">
                          - {{ dateFormatPicker(customYesterdayDate[1]) }}
                        </div>
                      </div>
                      <div
                        v-else
                        class="date-picker_input"
                      >
                        Yesterday
                      </div>
                    </template>
                    <template #icon-calendar>
                      <calendar-picker v-if="!customYesterdayDate" />
                      <calendar-picked v-else />
                    </template>
                  </date-picker>
                </th>
                <th>
                  <date-picker
                    v-model:value="customWeekDate"
                    :clearable="false"
                    :editable="false"
                    default-panel="day"
                    separator=" - "
                    :format="dateFormat"
                    placeholder="Current week"
                    :month-format="'MMMM'"
                    range
                    input-class="date-picker_input"
                    :class="{'dates_filled': customWeekDate}"
                    @change="getCustomStats(customWeekDate, 'week')"
                  >
                    <template #input>
                      <div
                        v-if="customWeekDate"
                        class="date-picker_input"
                      >
                        <div>{{ dateFormatPicker(customWeekDate[0]) }}</div>
                        <div v-if="dateFormatPicker(customWeekDate[1]) !== dateFormatPicker(customWeekDate[0])">
                          - {{ dateFormatPicker(customWeekDate[1]) }}
                        </div>
                      </div>
                      <div
                        v-else
                        class="date-picker_input"
                      >
                        Current week
                      </div>
                    </template>
                    <template #icon-calendar>
                      <calendar-picker v-if="!customWeekDate" />
                      <calendar-picked v-else />
                    </template>
                  </date-picker>
                </th>
                <th>
                  <date-picker
                    v-model:value="customMonthDate"
                    :editable="false"
                    :format="dateFormat"
                    placeholder="Current month"
                    range
                    input-class="date-picker_input"
                    :class="{'dates_filled': customMonthDate}"
                    @change="getCustomStats(customMonthDate, 'month')"
                  >
                    <template #input>
                      <div
                        v-if="customMonthDate"
                        class="date-picker_input"
                      >
                        <div>{{ dateFormatPicker(customMonthDate[0]) }}</div>
                        <div v-if="dateFormatPicker(customMonthDate[1]) !== dateFormatPicker(customMonthDate[0])">
                          - {{ dateFormatPicker(customMonthDate[1]) }}
                        </div>
                      </div>
                      <div
                        v-else
                        class="date-picker_input"
                      >
                        Current month
                      </div>
                    </template>
                    <template #icon-calendar>
                      <calendar-picker v-if="!customMonthDate" />
                      <calendar-picked v-else />
                    </template>
                  </date-picker>
                </th>
                <th>
                  <div class="text text-total">
                    Total
                  </div>
                </th>
              </tr>
            </thead>
            <tbody v-if="Object.keys(statsData).length">
              <tr class="table-row bottom top">
                <td class="table-cell table-cell-title">
                  <strong>Turnover (BTC) / tx</strong>
                </td>
                <td class="table-cell table-cell-today">
                  <div class="text">
                    <div class="text-value">
                      <strong>{{
                        (customTodayDate && customToday) ? (numberHandlerTurnover(customToday.turnover)) : numberHandlerTurnover(statsData.today.turnover)
                      }}</strong>
                    </div>
                    <div class="text-percentage">
                      <span v-if="statsData.today.cntOperacion !==0 || (customToday && customToday.cntOperacion !== 0)">
                        {{ customToday ? customToday.cntOperacion : statsData.today.cntOperacion }} tx.</span>
                      <span v-else>-</span>
                    </div>
                  </div>
                </td>
                <td class="table-cell table-cell-yesterday">
                  <div class="text">
                    <div class="text-value">
                      <strong>
                        {{
                          (customYesterdayDate && customYesterday) ? (numberHandlerTurnover(customYesterday.turnover)) : numberHandlerTurnover(statsData.yesterday.turnover)
                        }}
                      </strong>
                    </div>
                    <div class="text-percentage">
                      <span
                        v-if="statsData.yesterday.cntOperacion !==0 || (customYesterday && customYesterday.cntOperacion !== 0)"
                      >
                        {{
                          (customYesterdayDate && customYesterday) ? customYesterday.cntOperacion : statsData.yesterday.cntOperacion
                        }} tx.</span>
                      <span v-else>-</span>
                    </div>
                  </div>
                </td>
                <td class="table-cell table-cell-week">
                  <div class="text">
                    <div class="text-value">
                      <strong>
                        {{
                          (customWeekDate && customWeek) ? (numberHandlerTurnover(customWeek.turnover)) : numberHandlerTurnover(statsData.week.turnover)
                        }}
                      </strong>
                    </div>
                    <div class="text-percentage">
                      <span v-if="statsData.week.cntOperacion !==0 || (customWeek && customWeek.cntOperacion !== 0)">
                        {{
                          (customWeekDate && customWeek) ? customWeek.cntOperacion : statsData.week.cntOperacion
                        }} tx.</span>
                      <span v-else>-</span>
                    </div>
                  </div>
                </td>
                <td class="table-cell table-cell-month">
                  <div class="text">
                    <div class="text-value">
                      <strong>
                        {{
                          (customMonthDate && customMonth) ? (numberHandlerTurnover(customMonth.turnover)) : numberHandlerTurnover(statsData.month.turnover)
                        }}
                      </strong>
                    </div>
                    <div class="text-percentage">
                      <span v-if="statsData.month.cntOperacion !==0 || (customMonth && customMonth.cntOperacion !== 0)">
                        {{ (customMonthDate && customMonth) ? customMonth.cntOperacion : statsData.month.cntOperacion }} tx.</span>
                      <span v-else>-</span>
                    </div>
                  </div>
                </td>
                <td class="table-cell table-cell-total">
                  <div class="text">
                    <div class="text-value">
                      {{ numberHandlerTurnover(statsData.year.turnover) }}
                    </div>
                    <div class="text-percentage">
                      <span v-if="statsData.year.cntOperacion !==0">{{ statsData.year.cntOperacion }} tx.</span>
                      <span v-else>-</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="table-row bottom">
                <td class="table-cell table-cell-title">
                  Partner Profit
                </td>
                <td class="table-cell table-cell-today">
                  <div class="text">
                    <div class="text-value">
                      {{
                        (customTodayDate && customToday) ? (numberHandler(customToday.partnerProfit)) : numberHandler(statsData.today.partnerProfit)
                      }}
                    </div>
                    <div class="text-percentage">
                      {{
                        (customTodayDate && customToday) ? (calculatePartnerProfit(customToday)) : calculatePartnerProfit(statsData.today)
                      }}
                    </div>
                  </div>
                </td>
                <td class="table-cell table-cell-yesterday">
                  <div class="text">
                    <div class="text-value">
                      {{
                        (customYesterdayDate && customYesterday) ? (numberHandler(customYesterday.partnerProfit)) : numberHandler(statsData.yesterday.partnerProfit)
                      }}
                    </div>
                    <div class="text-percentage">
                      {{
                        (customYesterdayDate && customYesterday) ? (calculatePartnerProfit(customYesterday)) : calculatePartnerProfit(statsData.yesterday)
                      }}
                    </div>
                  </div>
                </td>
                <td class="table-cell table-cell-week">
                  <div class="text">
                    <div class="text-value">
                      {{
                        (customWeekDate && customWeek) ? (numberHandler(customWeek.partnerProfit)) : numberHandler(statsData.week.partnerProfit)
                      }}
                    </div>
                    <div class="text-percentage">
                      {{
                        (customWeekDate && customWeek) ? (calculatePartnerProfit(customWeek)) : calculatePartnerProfit(statsData.week)
                      }}
                    </div>
                  </div>
                </td>
                <td class="table-cell table-cell-month">
                  <div class="text">
                    <div class="text-value">
                      {{
                        (customMonthDate && customMonth) ? (numberHandler(customMonth.partnerProfit)) : numberHandler(statsData.month.partnerProfit)
                      }}
                    </div>
                    <div class="text-percentage">
                      {{
                        (customMonthDate && customMonth) ? (calculatePartnerProfit(customMonth)) : calculatePartnerProfit(statsData.month)
                      }}
                    </div>
                  </div>
                </td>
                <td class="table-cell table-cell-total">
                  <div class="text">
                    <div class="text-value">
                      {{ numberHandler(statsData.year.partnerProfit) }}
                    </div>
                    <div class="text-percentage">
                      {{ calculatePartnerProfit(statsData.year) }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="table-row bottom">
                <td class="table-cell table-cell-title">
                  Company Profit
                </td>
                <td class="table-cell table-cell-today">
                  <div class="text">
                    <div class="text-value">
                      {{
                        (customTodayDate && customToday) ? (numberHandler(customToday.companyProfit)) : numberHandler(statsData.today.companyProfit)
                      }}
                    </div>
                    <div class="text-percentage">
                      {{
                        (customTodayDate && customToday) ? (calculateCompanyProfit(customToday)) : calculateCompanyProfit(statsData.today)
                      }}
                    </div>
                  </div>
                </td>
                <td class="table-cell table-cell-yesterday">
                  <div class="text">
                    <div class="text-value">
                      {{
                        (customYesterdayDate && customYesterday) ? (numberHandler(customYesterday.companyProfit)) : numberHandler(statsData.yesterday.companyProfit)
                      }}
                    </div>
                    <div class="text-percentage">
                      {{
                        (customYesterdayDate && customYesterday) ? (calculateCompanyProfit(customYesterday)) : calculateCompanyProfit(statsData.yesterday)
                      }}
                    </div>
                  </div>
                </td>
                <td class="table-cell table-cell-week">
                  <div class="text">
                    <div class="text-value">
                      {{
                        (customWeekDate && customWeek) ? (numberHandler(customWeek.companyProfit)) : numberHandler(statsData.week.companyProfit)
                      }}
                    </div>
                    <div class="text-percentage">
                      {{
                        (customWeekDate && customWeek) ? (calculateCompanyProfit(customWeek)) : calculateCompanyProfit(statsData.week)
                      }}
                    </div>
                  </div>
                </td>
                <td class="table-cell table-cell-month">
                  <div class="text">
                    <div class="text-value">
                      {{
                        (customMonthDate && customMonth) ? (numberHandler(customMonth.companyProfit)) : numberHandler(statsData.month.companyProfit)
                      }}
                    </div>
                    <div class="text-percentage">
                      {{
                        (customMonthDate && customMonth) ? (calculateCompanyProfit(customMonth)) : calculateCompanyProfit(statsData.month)
                      }}
                    </div>
                  </div>
                </td>
                <td class="table-cell table-cell-total">
                  <div class="text">
                    <div class="text-value">
                      {{ numberHandler(statsData.year.companyProfit) }}
                    </div>
                    <div class="text-percentage">
                      {{ calculatePartnerProfit(statsData.year) }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <partners-activity v-if="!market" />
    </div>
  </div>
</template>

<script>
import Chart from "./Chart";
import {TRANSACTION_DATES, TRANSACTION_TABLE_TH} from "@/pages/dashboard/dashboardData";
import OrderService from "@/services/order.service";
import DatePicker from "vue-datepicker-next";
import dayjs from "dayjs";
import CalendarPicker from "@/icons/calendarPicker.vue";
import CalendarPicked from "@/icons/calendarPicked.vue";
import PartnersActivity from "@/pages/dashboard/partials/PartnersActivity.vue";
import MainChartUpdate from "@/pages/dashboard/partials/MainChartUpdate.vue";

export default {
  name: "TransactionsValue",
  components: {MainChartUpdate, PartnersActivity, CalendarPicked, CalendarPicker, DatePicker, Chart},
  props: {
    market: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      date: '',
      statsData: [],
      statsDataSave: [],
      dateFormat: 'DD.MM.YYYY',
      dateFormatUrl: 'YYYY-MM-DD',
      customToday: null,
      customTodayDate: null,
      customYesterday: null,
      customYesterdayDate: null,
      customWeek: null,
      customWeekDate: null,
      customMonth: null,
      customMonthDate: null,
      originStats: null,
      dates: TRANSACTION_DATES,
      tableTH: TRANSACTION_TABLE_TH,
      shortcuts: [
        {
          text: 'Today',
          onClick() {
            return new Date();
          },
        },
        {
          text: 'Yesterday',
          onClick() {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          },
        },
      ],
    }
  },
  watch: {
    market() {
      this.getStatsList()
    },
  },
  mounted() {
    this.getStatsList()
  },
  methods: {
    numberHandler(num) {
      if (num && num !== 0 && num !== null) {
        const [integerPart, decimalPart] = num.toFixed(6).split(".");
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${formattedIntegerPart}.${decimalPart}`;
      } else {
        return '-'
      }
    },
    numberHandlerTurnover(num) {
      if (num && num !== 0 && num !== null) {
        const [integerPart, decimalPart] = num.toFixed(6).split(".");
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${formattedIntegerPart}.${decimalPart}`;
      } else {
        return '-'
      }
    },
    getStatsList() {
      if (!this.market) {
        let query = ''
        OrderService.orderStatTotal(query).then((res) => {
          this.statsData = res.data.result
          this.statsDataSave = res.data.result
        })
      } else {
        OrderService.orderStatTotal( '/?filterExchange=' + this.market ).then((res) => {
          this.statsData = res.data.result
          this.statsDataSave = res.data.result
        })
      }
    },
    getCustomStats(date, model) {
      console.log(date);

      if (date) {
        const start = this.dateFormatter(date[0]);
        const end = this.dateFormatter(date[1]);


        if (!this.market) {
          OrderService.getStatTotalCustom('/?start=' + start + '&end=' + end).then((res) => {
            if (model === "today") {
              this.customToday = res.data.result;
            } else if (model === 'yesterday') {
              this.customYesterday = res.data.result;
            } else if (model === 'week') {
              this.customWeek = res.data.result;
            } else if (model === 'month') {
              this.customMonth = res.data.result;
            }
          });
        } else {
          OrderService.getStatTotalCustom('/?filterExchange=' + this.market + '&start=' + start + '&end=' + end).then((res) => {
            if (model === "today") {
              this.customToday = res.data.result;
            } else if (model === 'yesterday') {
              this.customYesterday = res.data.result;
            } else if (model === 'week') {
              this.customWeek = res.data.result;
            } else if (model === 'month') {
              this.customMonth = res.data.result;
            }
          });
        }
      } else {
        if (model === "today") {
          this.customToday = this.statsDataSave.today;
        } else if (model === 'yesterday') {
          this.customYesterday = this.statsDataSave.yesterday;
        } else if (model === 'week') {
          this.customWeek = this.statsDataSave.week;
        } else if (model === 'month') {
          this.customMonth = this.statsDataSave.month;
        }
      }
    },
    calculatePartnerProfit(obj) {
      const result = (obj.partnerProfit / obj.turnover * 100).toFixed(2)
      if (result > 0) {
        return result + '%'
      } else {
        return '-'
      }
    },
    calculateCompanyProfit(obj) {
      const result = (obj.companyProfit / obj.turnover * 100).toFixed(2)
      if (result > 0) {
        return result + '%'
      } else {
        return '-'
      }
    },
    dateFormatter(value) {
      return dayjs(value).format(this.dateFormatUrl)
    },
    dateFormatPicker(value) {

      return dayjs(value).format(this.dateFormat)
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/styles/pages/dashboard/_transactions-value.scss";
</style>
