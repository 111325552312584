<template>
  <div
    class="main-block exchangesListNew deposit-summary-list"
  >
    <div
      ref="tableBlockRef"
      class="table-block"
    >
      <div
        ref="stickyBlock"
        class="table-block-header-exchange"
      >
        <div>
          <div class="filters-component-container">
            <div class="filters-component-left-side">
              <h2>Statuses of deposits</h2>
              <DatesFilter
                :is-dates-open="isDatesOpen"
                @dateHandler="dateHandler"
              />
              <div
                v-if="selectedDate"
                class="selected-filter-container"
              >
                <div
                  v-if="selectedDate && selectedDate.length"
                  class="selected-filter-item"
                >
                  <span>Date:</span>
                  <span v-if="selectedDate[0].split('T')[0] === selectedDate[1].split('T')[0]">
                    {{ selectedDate[0].split('T')[0] }}
                  </span>
                  <span v-if="selectedDate[0].split('T')[0] !== selectedDate[1].split('T')[0]">
                    {{ selectedDate[0].split('T')[0] }} - {{ selectedDate[1].split('T')[0] }}
                  </span>
                  <FilterClose @click="dateHandler(null)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <table class="sticky-table-head">
          <thead>
            <tr>
              <th
                v-for="(item, index) in tableTh"
                :id="item.id"
                :key="index"
                :class="'table-' + item.key"
              >
                <div
                  class="text"
                  @click="sortData(item)"
                  @mouseover="tokenTo = index"
                  @mouseleave="tokenTo = null"
                >
                  {{ item.name }}
                  <span v-if="item.isSortable">
                    <sort-a-z v-if="item.sort === 'ASC' && currentSortRow === item.key" />
                    <sort-z-a v-if="item.sort === 'DESC' && currentSortRow === item.key" />
                    <sort-passive v-if="currentSortRow !== item.key" />
                  </span>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <PerfectScrollbar
        id="tableBlock"
        class="table-block-table"
        :class="[
          { 'without-height': isDepositSummaryLoading || depositSummaryData.length === 0 },
        ]"
      >
        <Transition name="committee">
          <table class="table">
            <thead>
              <tr class="table-headers">
                <th
                  v-for="(item, index) in tableTh"
                  :id="item.id"
                  :key="index"
                  scope="col"
                  :class="'table-' + item.key"
                >
                  <div
                    v-if="item.name === 'Total' && depositSummaryData.length !== 0"
                    class="shadow-total"
                  />
                  <div
                    v-if="item.name === 'Coin' && depositSummaryData.length !== 0"
                    class="shadow-coin"
                  />
                  <div
                    class="text"
                    @click="sortData(item)"
                    @mouseover="tokenTo = index"
                    @mouseleave="tokenTo = null"
                  >
                    {{ item.name }}
                    <span v-if="item.isSortable">
                      <sort-a-z v-if="item.sort === 'asc'" />
                      <sort-z-a v-if="item.sort === 'desc'" />
                      <sort-passive v-if="!item.sort" />
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody
              v-if="!isDepositSummaryLoading"
            >
              <tr
                v-for="(row, index) in parsedSummaryData"
                :key="index"
                class="deposits-summary-list-item-tr"
              >
                <td class="td-coin">
                  <div class="shadow-coin" />
                  <CoinsSvg
                    :coin="row.coin.split('_')[1]"
                    :coins-list="originCoins"
                  />
                  <span>{{ `${row.coin.split('_')[1]} (${row.coin.split('_')[0]})` }}</span>
                </td>

                <td class="td-new">
                  <template v-if="row.new === '—'">
                    {{ row.new }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`new_${row.coin}`) }"
                      @click="selectStatusHandler('new', row.coin)"
                    >
                      {{ row.new }}
                    </button>
                  </template>
                </td>

                <td class="td-unconfirmed">
                  <template v-if="row.unconfirmed === '—'">
                    {{ row.unconfirmed }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`unconfirmed_${row.coin}`) }"
                      @click="selectStatusHandler('unconfirmed', row.coin)"
                    >
                      {{ row.unconfirmed }}
                    </button>
                  </template>
                </td>

                <td class="td-sent">
                  <template v-if="row.sent === '—'">
                    {{ row.sent }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`sent_${row.coin}`) }"
                      @click="selectStatusHandler('sent', row.coin)"
                    >
                      {{ row.sent }}
                    </button>
                  </template>
                </td>

                <td class="td-closed">
                  <template v-if="row.closed === '—'">
                    {{ row.closed }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`closed_${row.coin}`) }"
                      @click="selectStatusHandler('closed', row.coin)"
                    >
                      {{ row.closed }}
                    </button>
                  </template>
                </td>

                <td class="td-overdue">
                  <template v-if="row.overdue === '—'">
                    {{ row.overdue }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`overdue_${row.coin}`) }"
                      @click="selectStatusHandler('overdue', row.coin)"
                    >
                      {{ row.overdue }}
                    </button>
                  </template>
                </td>

                <td class="td-rerouted">
                  <template v-if="row.rerouted === '—'">
                    {{ row.rerouted }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`rerouted_${row.coin}`) }"
                      @click="selectStatusHandler('rerouted', row.coin)"
                    >
                      {{ row.rerouted }}
                    </button>
                  </template>
                </td>

                <td class="td-onPause">
                  <template v-if="row.onPause === '—'">
                    {{ row.onPause }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`onPause_${row.coin}`) }"
                      @click="selectStatusHandler('onPause', row.coin)"
                    >
                      {{ row.onPause }}
                    </button>
                  </template>
                </td>

                <td class="td-onHold">
                  <template v-if="row.onHold === '—'">
                    {{ row.onHold }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`onHold_${row.coin}`) }"
                      @click="selectStatusHandler('onHold', row.coin)"
                    >
                      {{ row.onHold }}
                    </button>
                  </template>
                </td>

                <td class="td-serviceOnHold">
                  <template v-if="row.serviceOnHold === '—'">
                    {{ row.serviceOnHold }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`serviceOnHold_${row.coin}`) }"
                      @click="selectStatusHandler('serviceOnHold', row.coin)"
                    >
                      {{ row.serviceOnHold }}
                    </button>
                  </template>
                </td>

                <td class="td-wrongNetwork">
                  <template v-if="row.wrongNetwork === '—'">
                    {{ row.wrongNetwork }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`wrongNetwork_${row.coin}`) }"
                      @click="selectStatusHandler('wrongNetwork', row.coin)"
                    >
                      {{ row.wrongNetwork }}
                    </button>
                  </template>
                </td>

                <td class="td-disabled">
                  <template v-if="row.disabled === '—'">
                    {{ row.disabled }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`disabled_${row.coin}`) }"
                      @click="selectStatusHandler('disabled', row.coin)"
                    >
                      {{ row.disabled }}
                    </button>
                  </template>
                </td>

                <td class="td-routing">
                  <template v-if="row.routing === '—'">
                    {{ row.routing }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`routing_${row.coin}`) }"
                      @click="selectStatusHandler('routing', row.coin)"
                    >
                      {{ row.routing }}
                    </button>
                  </template>
                </td>

                <td class="td-stopped">
                  <template v-if="row.stopped === '—'">
                    {{ row.stopped }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`stopped_${row.coin}`) }"
                      @click="selectStatusHandler('stopped', row.coin)"
                    >
                      {{ row.stopped }}
                    </button>
                  </template>
                </td>

                <td class="td-refunded">
                  <template v-if="row.refunded === '—'">
                    {{ row.refunded }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`refunded_${row.coin}`) }"
                      @click="selectStatusHandler('refunded', row.coin)"
                    >
                      {{ row.refunded }}
                    </button>
                  </template>
                </td>

                <td class="td-refill">
                  <template v-if="row.refill === '—'">
                    {{ row.refill }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`refill_${row.coin}`) }"
                      @click="selectStatusHandler('refill', row.coin)"
                    >
                      {{ row.refill }}
                    </button>
                  </template>
                </td>

                <td class="td-checked">
                  <template v-if="row.checked === '—'">
                    {{ row.checked }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`checked_${row.coin}`) }"
                      @click="selectStatusHandler('checked', row.coin)"
                    >
                      {{ row.checked }}
                    </button>
                  </template>
                </td>

                <td class="td-receiving">
                  <template v-if="row.receiving === '—'">
                    {{ row.receiving }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`receiving_${row.coin}`) }"
                      @click="selectStatusHandler('receiving', row.coin)"
                    >
                      {{ row.receiving }}
                    </button>
                  </template>
                </td>

                <td class="td-blacklisted">
                  <template v-if="row.blacklisted === '—'">
                    {{ row.blacklisted }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`blacklisted_${row.coin}`) }"
                      @click="selectStatusHandler('blacklisted', row.coin)"
                    >
                      {{ row.blacklisted }}
                    </button>
                  </template>
                </td>

                <td class="td-segmented">
                  <template v-if="row.segmented === '—'">
                    {{ row.segmented }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`segmented_${row.coin}`) }"
                      @click="selectStatusHandler('segmented', row.coin)"
                    >
                      {{ row.segmented }}
                    </button>
                  </template>
                </td>

                <td class="td-sending">
                  <template v-if="row.sending === '—'">
                    {{ row.sending }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`sending_${row.coin}`) }"
                      @click="selectStatusHandler('sending', row.coin)"
                    >
                      {{ row.sending }}
                    </button>
                  </template>
                </td>

                <td class="td-received">
                  <template v-if="row.received === '—'">
                    {{ row.received }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`received_${row.coin}`) }"
                      @click="selectStatusHandler('received', row.coin)"
                    >
                      {{ row.received }}
                    </button>
                  </template>
                </td>

                <td class="td-refunding">
                  <template v-if="row.refunding === '—'">
                    {{ row.refunding }}
                  </template>
                  <template v-else>
                    <button
                      :class="{ 'active': selectedCoinsAndStatuses.includes(`refunding_${row.coin}`) }"
                      @click="selectStatusHandler('refunding', row.coin)"
                    >
                      {{ row.refunding }}
                    </button>
                  </template>
                </td>

                <td class="td-total">
                  <div class="shadow-total" />
                  <template v-if="row.total === '—'">
                    {{ row.total }}
                  </template>
                  <template v-else>
                    {{ row.total }}
                  </template>
                </td>
              </tr>
              <tr
                v-if="totalsByStatuses"
                class="deposits-summary-list-item-tr"
              >
                <td class="td-coin">
                  <div class="shadow-coin" />
                  Total
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.new ? totalsByStatuses.new : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.unconfirmed ? totalsByStatuses.unconfirmed : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.sent ? totalsByStatuses.sent : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.closed ? totalsByStatuses.closed : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.overdue ? totalsByStatuses.overdue : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.rerouted ? totalsByStatuses.rerouted : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.onPause ? totalsByStatuses.onPause : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.onHold ? totalsByStatuses.onHold : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.serviceOnHold ? totalsByStatuses.serviceOnHold : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.wrongNetwork ? totalsByStatuses.wrongNetwork : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.disabled ? totalsByStatuses.disabled : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.routing ? totalsByStatuses.routing : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.stopped ? totalsByStatuses.stopped : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.refunded ? totalsByStatuses.refunded : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.refill ? totalsByStatuses.refill : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.checked ? totalsByStatuses.checked : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.receiving ? totalsByStatuses.receiving : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.blacklisted ? totalsByStatuses.blacklisted : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.segmented ? totalsByStatuses.segmented : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.sending ? totalsByStatuses.sending : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.received ? totalsByStatuses.received : '—' }}
                </td>
                <td class="td-total-bottom">
                  {{ totalsByStatuses.refunding ? totalsByStatuses.refunding : '—' }}
                </td>

                <td class="td-total">
                  <div class="shadow-total" />
                  {{ totalsByStatuses.total ? totalsByStatuses.total : '—' }}
                </td>
              </tr>
            </tbody>
          </table>
        </Transition>
      </PerfectScrollbar>
    <!--  paginator was here   -->
    </div>
    <trade-loader v-if="isDepositSummaryLoading" />
    <TradeNoData v-if="!parsedSummaryData?.length && !isDepositSummaryLoading" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SortZA from '@/icons/sorting/sort-z-a.vue';
import SortAZ from '@/icons/sorting/sort-a-z.vue';
import TradeNoData from '@/components/trade/components/TradeNoData.vue';
import SortPassive from '@/icons/sorting/sort-passive.vue';
import TradeLoader from '@/components/trade/components/TradeLoader.vue';
import {TABLE_TH} from '@/pages/deposits/depositSummaryData';
import DatesFilter from '@/components/admin/DatesFilter.vue';
import CoinsSvg from '@/pages/balance/CoinsSvg.vue';
import CoinsService from '@/services/coins.service';
import FilterClose from '@/icons/filterClose.vue';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'

export default {
  name: "DepositSummaryList",
  components: {
    FilterClose,
    CoinsSvg,
    DatesFilter,
    TradeLoader,
    SortPassive,
    TradeNoData,
    PerfectScrollbar,
    SortAZ,
    SortZA,
  },
  props: {
    depositSummaryData: {
      type: Array,
      required: true,
    },
    isDepositSummaryLoading: {
      type: Boolean,
      required: true,
    },
    selectedCoinsAndStatuses: {
      type: Array,
      required: true,
    },
    selectedDate: {
      type: Array,
      required: true,
    },
    dateHandler: {
      type: Function,
      required: true,
    }
  },
  emits: ['toggleDropdown', 'closeDropdown', 'update-isDepositSummaryLoading', 'update-selected'],
  data() {
    return {
      tableTh: TABLE_TH,
      tokenTo: false,
      currentSortRow: null,
      // date picker
      isDatesOpen: true,
      parsedSummaryData: [],
      totalsByStatuses: null,
      originCoins: null,
    }
  },
  watch: {
    depositSummaryData: {
      handler: 'parsingDepositSummaryData',
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      Notification: 'getNotification',
    }),
  },
  mounted() {
    this.getCoinsList()
  },
  beforeUnmount() {

  },
  methods: {
    parsingDepositSummaryData(newData) {
      if (newData.length === 0) {
        this.$emit('update-isDepositSummaryLoading', false);
        return false
      }

      const tableDataMap = {};

      newData.forEach(item => {
        const { tokenSymbol, netId, status, count } = item;
        const key = `${tokenSymbol}_${netId}`;

        if (!tableDataMap[key]) {
          tableDataMap[key] = {
            coin: `${netId}_${tokenSymbol}`,
          };

          this.tableTh.forEach(header => {
            if (header.id !== 'coin' && header.id !== 'total') {
              tableDataMap[key][header.id] = '—';
            }
          });

          tableDataMap[key]['total'] = 0;
        }

        tableDataMap[key][this.camelize(status)] = count;
        tableDataMap[key]['total'] += count;
      });

      this.totalsByStatuses = this.calculateTotalByStatus(Object.values(tableDataMap));
      this.parsedSummaryData = Object.values(tableDataMap);
      this.$emit('update-isDepositSummaryLoading', false);
    },
    selectStatusHandler(status, coin) {
      this.$emit('update-selected', { status, coin });
    },
    calculateTotalByStatus(data) {
      return data.reduce((totals, item) => {
        Object.keys(item).forEach(key => {
          if (key !== "coin" && item[key] !== "—") {
            const value = Number(item[key]) || 0;
            totals[key] = (totals[key] || 0) + value;
          }
        });
        return totals;
      }, {});
    },
    // sort
    sortData(type) {
      this.tableTh = this.tableTh.map((item) => {
        if (item.key === type.key) {
          item.sort = type.sort === 'asc' ? 'desc' : 'asc';
        } else {
          item.sort = '';
        }

        return item;
      });
      switch (type.key) {
        case 'coin':
          this.parsedSummaryData = this.parsedSummaryData.sort((a, b) => {
            const aCount = a.coin.localeCompare(b.coin);
            const bCount = b.coin.localeCompare(a.coin);

            return type.sort === 'asc' ? aCount : bCount;
          });
          break;

        default:
          break;
      }
    },
    getCoinsList() {
      CoinsService.getCoinsListData().then((res) => {
        this.originCoins = res.data.result
      })
    },
    camelize(str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}
}}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables';

.deposit-summary-list {
  //overflow-x: scroll;
  min-height: auto;
  margin-bottom: -8px;

  @media (max-width: 1079px) {
    min-height: auto;
    @media (max-width: 819px) {
      overflow-x: auto;
      .mx-datepicker {
        display: block;
      }
    }
  }

  .table-block-table {
    overflow: auto;
    margin-bottom: 6px;
    padding-bottom: 10px;
    @media (max-width: 819px) {
      height: auto;
    }
  }
}

.table-headers {
  th {
    text-align: center !important;
    width: 56px;
    padding: 0 2px;

    >div.text {
      width: 56px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  th.table-coin {
    position: sticky;
    background: #fff;
    left: 0;
    z-index: 1;
    width: 176px;
    min-width: 176px;
    padding-left: 32px;
    box-sizing: border-box;
    >div.text {
      text-align: left;
    }

    @media (max-width: 1079px) {
      padding-left: 16px;
    }
  }

  th.table-total {
    position: sticky;
    background: #fff;
    right: 0;
    z-index: 1;
    width: 104px;
    min-width: 104px;
    padding-right: 32px;
    text-align: right !important;
    box-sizing: border-box;
    >div.text {
      width: 100%;
      text-align: right;
    }

    @media (max-width: 1079px) {
      padding-right: 16px;
    }
  }
}

.deposits-summary-list-item-tr {
  position: relative;
  height: 40px;
  transition: .3s;

  &:hover {
    background: #F5F9FF;
  }

  td {
    position: relative;
    text-align: center;
    vertical-align: middle;
    padding: 0 2px;
    height: 40px;
    width: 56px;
    box-sizing: border-box;
    color: var(--light-gary, #A1BBD9);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.2px;

    button {
      height: 32px;
      width: 56px;
      color: var(--buel, #0065FF);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.2px;
      border-radius: 8px;
      border: none;
      background: rgba(0, 101, 255, 0.08);
      transition: background .3s;
      cursor: pointer;

      &:hover {
        background: rgba(0, 101, 255, 0.12);
      }
    }

    button.active {
      background: var(--buel, #0065FF);
      color: white;
    }

    /*@media (max-width: 1079px) {
      height: 48px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.24px;
    }*/
  }

  td.td-coin {
    position: sticky;
    left: 0;
    z-index: 1;
    text-align: left;
    padding-left: 32px;
    color: var(--black, #1A242B);
    background: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.28px;
    transition: 0.3s;
    width: 176px;

    display: table-cell;

    @media (max-width: 1079px) {
      padding-left: 16px;
    }
  }

  td.td-coin .icon {
    display: inline-block;
    position: relative;
    top: 6px;
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }


  td.td-total {
    position: sticky;
    right: 0;
    z-index: 1;
    padding-right: 32px;
    color: var(--gary, #527AA1);
    background: #fff;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    transition: 0.3s;

    @media (max-width: 1079px) {
      padding-right: 16px;
    }
  }

  td.td-total-bottom {
    color: var(--gary, #527AA1);
    text-align: center;
    font-size: 12px;
    height: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

div.shadow-total {
  position: absolute;
  top: 0;
  left: -5px;
  height: 100%;
  width: 5px;
  background: linear-gradient(to left, rgba(82, 122, 161, 0.12), rgba(0, 0, 0, 0));
}

div.shadow-coin {
  position: absolute;
  top: 0;
  right: -5px;
  height: 100%;
  width: 5px;
  background: linear-gradient(to right, rgba(82, 122, 161, 0.12), rgba(0, 0, 0, 0));
}

.deposits-summary-list-item-tr:hover > td.td-coin, .deposits-summary-list-item-tr:hover > td.td-total {
  background: #F5F9FF;
}
</style>
