<template>
  <div
    v-if="exchange.depositNodeId"
    id="scrollable"
    class="exchange-info-form-inputs-block exchange-info-form-inputs-block_node"
  >
    <div class="exchange-info-form-inputs-block_items">
      <div class="exchange-info-form-inputs-block-item">
        <div class="desc">
          Deposit ID
        </div>
        <div class="depositId">
          <div class="id">
            {{ exchange.depositNodeId }}
          </div>
          <div
            v-if="exchange.nodeStatus"
            class="status"
            :class="{
              'status-gray': exchange.nodeStatus === 'new',
              'status-blue': exchange.nodeStatus === 'unconfirmed' || exchange.nodeStatus === 'receiving' || exchange.nodeStatus === 'received' || exchange.nodeStatus === 'checking',
              'status-green': exchange.nodeStatus === 'sent',
              'status-red': exchange.nodeStatus === 'canceled' || exchange.nodeStatus === 'refill' || exchange.nodeStatus === 'overdue' || exchange.nodeStatus === 'on hold' || exchange.nodeStatus === 'refunded' || exchange.nodeStatus === 'on pause' || exchange.nodeStatus === 'segmented'
            }"
          >
            <div class="circle" />
            {{ exchange.nodeStatus }}
          </div>
        </div>
        <div />
      </div>
      <div
        v-if="exchange.nodeDxId"
        class="exchange-info-form-inputs-block-item"
        :class="{'exchange-info-form-inputs-block-item-error' : exchange.nodeError}"
      >
        <div class="desc">
          Hash In
        </div>
        <div
          v-clipboard:copy="exchange.nodeDxId"
          v-clipboard:success="onCopyAddress"
          class="input"
        >
          <span>{{ hashFormat(exchange.nodeDxId) }}</span>
          <copy-icon-gray class="copyIcon" />
        </div>
        <div
          v-if="exchange.nodeError"
          class="error"
        >
          {{ exchange.nodeError }}
        </div>
      </div>
      <div
        v-if="exchange.nodeWxId"
        class="exchange-info-form-inputs-block-item"
      >
        <div class="desc">
          Hash Out
        </div>
        <div
          v-clipboard:copy="exchange.nodeWxId"
          v-clipboard:success="onCopyAddress"
          class="input"
        >
          <span>{{ hashFormat(exchange.nodeWxId) }}</span>
          <copy-icon-gray class="copyIcon" />
        </div>
      </div>
      <div
        v-if="exchange.amlCheck"
        class="exchange-info-form-inputs-block-item"
      >
        <div class="desc">
          AML / AML ID
        </div>
        <div
          v-clipboard:copy="exchange.amlCheck"
          v-clipboard:success="onCopyAddress"
          class="input"
        >
          <check-true-icon v-if="exchange.amlScore <= 60" />
          <check-false-icon v-if="exchange.amlScore > 60" />
          {{ exchange.amlCheck }}

          <copy-icon-gray class="copyIcon" />
        </div>

        <div class="exchange-info-form-inputs-block-item_aml">
          <span :class="{'high-risk': exchange.amlScore > 60}"><b>RiskScore: {{ exchange.amlScore }}%</b></span>
          <div
            v-for="(item, index) in filteredAmlSignal"
            :key="index"
            class="aml-item"
            :class="{
              'high-risk': isHighRisk(item.key, item.value)
            }"
          >
            Signal <span>{{ item.key }}</span> {{ item.value }}%
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="allowedStatusesForRefund().includes(exchange.nodeStatus) && isHolded || (exchange.nodeStatus === 'sent' && exchange.status === 6)"
      class="exchange-info-form-inputs-block-item exchange-info-form-inputs-block-item_check"
    >
      <div class="exchange-info-form-inputs-block-item_check-block">
        <div class="warning">
          <warning-icon />
          <div v-if="exchange.nodeStatus === 'sent' && exchange.status === 6" class="warning-desc">
            Возврат повторного депозита
          </div>
          <div v-else class="warning-desc">
            Возврат делает после утверждения в чате. инфо
            <a
              href="https://t.me/ev_swpz"
              target="_blank"
            >
              @rad1111k
            </a>
          </div>
        </div>

        <div
          v-if="allowedStatusesForRefund().includes(exchange.nodeStatus)"
          class="check-buttons"
        >
          <div
            class="check-buttons--item"
            @click="refundStatusHandler('continue')"
          >
            <check-button-icon :status="refundStatus === 'continue'" />
            <span>Continue</span>
          </div>
          <div
            class="check-buttons--item"
            @click="refundStatusHandler('refund')"
          >
            <check-button-icon :status="refundStatus === 'refund'" />
            <span>Refund</span>
          </div>
          <div
            class="check-buttons--item"
            @click="refundStatusHandler('market')"
          >
            <check-button-icon :status="refundStatus === 'market'" />
            <span>Market</span>
          </div>
        </div>

        <div
          v-if="refundStatus !== 'continue' && refundStatus !== 'market'"
          class="refund-address"
        >
          <div class="desc">
            Refund address
          </div>
          <input
            v-model="refundAddress"
            type="text"
            placeholder="Refund address"
            pattern="[a-zA-Z0-9]*"
            @input="checkRefundAddress"
          >
        </div>

        <div
          v-if="refundStatus === 'market'"
          id="content"
          class="selected-list"
        >
          <div
            class="exchange-info-form-inputs-block-item"
            @click="marketOptions"
          >
            <div class="desc">
              Market
            </div>
            <div class="input">
              <div class="input-text">
                <div>
                  {{ selectedMarket }}
                </div>
              </div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_6635_927)">
                  <path
                    d="M13.825 7.15833L10 10.975L6.175 7.15833L5 8.33333L10 13.3333L15 8.33333L13.825 7.15833Z"
                    fill="#9FA2B4"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6635_927">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div
            v-if="isMarketList"
            id="dropdown"
            class="dropdown"
          >
            <div
              v-for="(item, index) in marketsList"
              :key="index"
              class="dropdown--item"

              @click="selectedMarket = item; isMarketList = false"
            >
              <div class="text">
                <div class="name">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>

        </div>
        <div
          v-if="exchange.from.shortName === 'BTC' && (exchange.nodeStatus === 'stopped' || exchange.nodeStatus === 'segmented' || exchange.nodeStatus === 'checking limit' || exchange.nodeStatus === 'on hold' || exchange.nodeStatus === 'on pause' || (exchange.nodeStatus === 'sent' && exchange.status === 6))"
          id="content"
          class="selected-list"
        >
          <div
            class="exchange-info-form-inputs-block-item"
            @click="dropSpeedOptions"
          >
            <div class="desc">
              Fee options / Speed
            </div>
            <div class="input">
              <div class="input-text">
                <div>
                  {{ selectedOption.option }} /
                  <span v-if="selectedOption.option === 'slow'">
                    1 hour
                  </span>
                  <span v-if="selectedOption.option === 'medium'">
                    30 minutes
                  </span>
                  <span v-if="selectedOption.option === 'fast'">
                    10 minutes
                  </span>
                </div>

                <span class="input-text_bold">
                  {{ selectedOption.cost }} BTC
                </span>
              </div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_6635_927)">
                  <path
                    d="M13.825 7.15833L10 10.975L6.175 7.15833L5 8.33333L10 13.3333L15 8.33333L13.825 7.15833Z"
                    fill="#9FA2B4"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6635_927">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div
            v-if="isDropSpeedList"
            id="dropdown"
            class="dropdown"
          >
            <div
              v-for="(item, index) in speedOptions"
              :key="index"
              class="dropdown--item"

              @click="selectedOption = item; isDropSpeedList = false"
            >
              <div class="text">
                <div class="name">
                  {{ item.option }} / <span v-if="selectedOption.option === 'slow'">
                    1 hour
                  </span>
                  <span v-if="selectedOption.option === 'medium'">
                    30 minutes
                  </span>
                  <span v-if="selectedOption.option === 'fast'">
                    10 minutes
                  </span>
                </div>
                <div class="desc">
                  {{ item.description }}
                </div>
              </div>

              <div class="value">
                <div class="value-cost">
                  {{ item.cost }} {{ exchange.from.shortName }}
                </div>

                <div class="value-usdt">
                  {{ item.costUsdt }} USDT
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="refundStatus === 'market'"
          class="save"
          @click="confirmChangeMarket"
        >
          Confirm
        </div>
        <div
          v-else-if="!validateRefund"
          class="save save-disabled"
        >
          Confirm
        </div>
        <div
          v-else
          class="save"
          @click="confirmRefund"
        >
          Confirm
        </div>
      </div>
    </div>
    <div
      v-if="(exchange.nodeStatus === 'sending' || exchange.nodeStatus === 'pay' || exchange.nodeStatus === 'refunding') && isHolded"
      class="exchange-info-form-inputs-block-item exchange-info-form-inputs-block-item_check"
    >
      <div class="exchange-info-form-inputs-block-item_check-block">
        <div class="warning">
          <warning-icon />
          <div class="warning-desc">
            Ускорение депозита после утверждения в чате. инфо
            <a
              href="https://t.me/ev_swpz"
              target="_blank"
            >
              @rad1111k
            </a>
          </div>
        </div>
        <div
          v-if="exchange.from.shortName === 'BTC'"
          id="content"
          class="selected-list"
        >
          <div
            class="exchange-info-form-inputs-block-item"
            @click="dropSpeedOptions"
          >
            <div class="desc">
              Fee options / Speed
            </div>
            <div class="input">
              <div class="input-text">
                <div>
                  {{ selectedOption.option }} /
                  <span v-if="selectedOption.option === 'slow'">
                    1 hour
                  </span>
                  <span v-if="selectedOption.option === 'medium'">
                    30 minutes
                  </span>
                  <span v-if="selectedOption.option === 'fast'">
                    10 minutes
                  </span>
                </div>

                <span class="input-text_bold">
                  {{ selectedOption.cost }} BTC
                </span>
              </div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_6635_927)">
                  <path
                    d="M13.825 7.15833L10 10.975L6.175 7.15833L5 8.33333L10 13.3333L15 8.33333L13.825 7.15833Z"
                    fill="#9FA2B4"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6635_927">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div
            v-if="isDropSpeedList"
            id="dropdown"
            class="dropdown"
          >
            <div
              v-for="(item, index) in speedOptions"
              :key="index"
              class="dropdown--item"

              @click="selectedOption = item; isDropSpeedList = false"
            >
              <div class="text">
                <div class="name">
                  {{ item.option }} / <span v-if="selectedOption.option === 'slow'">
                    1 hour
                  </span>
                  <span v-if="selectedOption.option === 'medium'">
                    30 minutes
                  </span>
                  <span v-if="selectedOption.option === 'fast'">
                    10 minutes
                  </span>
                </div>
                <div class="desc">
                  {{ item.description }}
                </div>
              </div>

              <div class="value">
                <div class="value-cost">
                  {{ item.cost }} {{ exchange.from.shortName }}
                </div>

                <div class="value-usdt">
                  {{ item.costUsdt }} USDT
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="save"
          @click="confirmBump"
        >
          Confirm
        </div>
      </div>
    </div>
    <div
      v-if="exchange.nodeStatus === 'received' && exchange.nodeError || exchange.nodeStatus === 'refill' || exchange.nodeStatus === 'checked' || exchange.nodeStatus === 'checking AML'"
      class="exchange-info-form-inputs-block-item exchange-info-form-inputs-block-item_check"
    >
      <div class="exchange-info-form-inputs-block-item_check-block">
        <div class="warning">
          <warning-icon />
          <div class="warning-desc">
            Restart the deposit after replenishing the node address
          </div>
        </div>
        <div
          class="save"
          @click="confirmRestart"
        >
          Confirm
        </div>
      </div>
    </div>
    <div
      v-if="exchange.nodeStatus === 'new' && exchange.addressFromNetwork === 'ETH' && 1 === 2"
      class="exchange-info-form-inputs-block-item exchange-info-form-inputs-block-item_check"
    >
      <div class="exchange-info-form-inputs-block-item_check-block">
        <div class="warning">
          <warning-icon />
          <div class="warning-desc">
            Если транзакции отправлена через смарт-контракт заполните сюда хеш и сумму как в транзакции
          </div>
        </div>
        <div
          class="refund-address"
        >
          <div class="desc">
            Hash in
          </div>
          <input
            v-model="hashInData"
            type="text"
            placeholder="Hash in"
          >
        </div>
        <div
          class="refund-address"
        >
          <div class="desc">
            Amount
          </div>
          <input
            v-model="amountData"
            type="text"
            placeholder="Amount"
          >
        </div>
        <div
          class="save"
          @click="confirmPatch"
        >
          Confirm
        </div>
      </div>
    </div>
  </div>

  <CopyNotification
    v-if="isSuccessCopy"
    :text="copyText"
  />
</template>

<script>
import {STATUSES} from "@/pages/exchanges/exchangeData";
import CheckTrueIcon from "@/assets/img/icons/checkTrueIcon.vue";
import CheckFalseIcon from "@/assets/img/icons/checkFalseIcon.vue";
import CopyIconGray from "@/assets/img/icons/copyIconGray.vue";
import CopyNotification from "@/pages/notification/CopyNotification.vue";
import WarningIcon from "@/icons/WarningIcon.vue";
import CheckButtonIcon from "@/icons/checkButtonIcon.vue";
import OrderService from "@/services/order.service";
import {mapGetters} from "vuex";
import UserService from '@/services/user.service';
import AdminService from '@/services/admin.service';
import {allowedStatusesForRefund, allowedStatusesForRestart} from '@/store/config';

export default {
  name: "ExchangeInfoNode",
  components: {CheckButtonIcon, WarningIcon, CopyNotification, CopyIconGray, CheckFalseIcon, CheckTrueIcon},
  props: {
    exchange: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      exchangeData: null,
      ExchangeStatusList: STATUSES,
      copyText: '',
      isSuccessCopy: true,
      refundAddress: '',
      validateRefund: false,
      refundStatus: '',
      isHolded: true,
      isDropSpeedList: false,
      isMarketList: false,
      selectedOption: {},
      speedOptions: [],
      hashInData: '',
      amountData: '',
      amlCheck: {
        "Sanctions": 5,
        "Gambling": 5,
        "Marketplace": 5,
        "EnforcementAction": 2,
        "Ransom": 2,
        "Scam": 2,
        "DarkMarket": 5,
        "DarkService": 5,
        "Mixer": 2,
        "StolenCoins": 2,
      },
      marketsList: [],
      selectedMarket: ""
    }
  },
  computed: {
    ...mapGetters({
      Notification: 'getNotification',
    }),
    filteredAmlSignal() {
      return this.exchange.amlSignal ? Object.entries(this.exchange.amlSignal).filter(([key, value]) => value > 0).map(([key, value]) => ({
        key,
        value
      })) : [];
    },
  },
  watch: {
    refundAddress(newValue) {
      if (this.exchange.nodeStatus === 'on hold') {
        this.validateRefund = newValue.trim().length >= 10;
      }

      if (this.exchange.nodeStatus === 'on pause') {
        this.validateRefund = newValue.trim().length >= 10 && this.refundStatus !== '';
      }
    },
    refundStatus(newValue) {
      if (allowedStatusesForRefund.includes(this.exchange.nodeStatus)) {
        this.validateRefund = this.refundAddress.trim().length >= 10 && newValue !== '';
      }

      if (allowedStatusesForRefund.includes(this.exchange.nodeStatus) && this.refundStatus === 'continue') {
        this.validateRefund = true;
      }
    }
  },
  mounted() {
    this.exchangeData = this.exchange;
    this.getFeeOptions();
    this.getMarket();
  },
  methods: {
    allowedStatusesForRefund() {
      return allowedStatusesForRefund
    },
    isHighRisk(key, value) {
      const thresholds = {
        "sanctions":         5,
        "gambling":          5,
        "marketplace":       5,
        "enforcementAction": 2,
        "ransom":            2,
        "scam":              2,
        "darkMarket":        5,
        "darkService":       5,
        "mixer":             2,
        "stolenCoins":       2
      };

      return value > thresholds[key];
    },
    testBump() {
      this.setNotification({name: 'ID ' + this.exchange.id + ' patch started'});
      this.emitter.off('update-notification');
      this.emitter.on('update-notification', () => {
        if (this.Notification && this.Notification.name) {
          this.successPopup('patch')
        }
      })
    },
    setNotification(data) {
      let result = {};

      if (data.name) {
        result = {
          name: data.name,
          value: data.value,
        }
      }
      this.$store.commit('setNotification', result);
    },
    confirmPatch() {
      this.setNotification({name: 'ID ' + this.exchange.depositNodeId + ', patch started'});
      this.emitter.off('update-notification');
      this.emitter.on('update-notification', () => {
        if (this.Notification && this.Notification.name) {
          OrderService.patchOrder(this.exchange.id, this.hashInData, this.amountData)
              .then((res) => {
                this.successPopup('patch')
              })
              .catch(error => {
                if (error) {
                  this.errorPopup( error.response.data.message)
                }
              })
        }
      })
    },
    getFeeOptions() {
      OrderService.getFeeOptions().then((res) => {
        this.speedOptions = res.data.result;

        this.selectedOption = this.speedOptions.find((item) => {
          return item.option === 'medium'
        })
      })
    },
    refundStatusHandler(status) {
      if (this.refundStatus !== status) {
        this.refundStatus = status
      } else {
        this.refundStatus = ''
      }
    },
    dropSpeedOptions() {
      this.isDropSpeedList = !this.isDropSpeedList;
    },
    marketOptions() {
      this.isMarketList = !this.isMarketList;
    },
    hashFormat(val) {
      if (val.length > 60) {
        return val.slice(0, 59) + '...'
      } else {
        return val
      }
    },
    checkRefundAddress() {
      this.validateRefund = this.refundAddress.trim().length >= 10;
    },
    confirmRefund() {
      if (confirm('Are you sure?')) {
        if (allowedStatusesForRefund.includes(this.exchange.nodeStatus)) {
          if (this.refundStatus !== 'continue') {
            this.refundOrderNode();
          } else {
            this.restartOrderNode();
          }
        }

        if (this.exchange.nodeStatus === 'sent' && this.exchange.status === 6) {
          this.refundDuplicateHandler();
        }

        if (allowedStatusesForRestart.includes(this.exchange.nodeStatus)) {
          this.restartOrderNode();
        }

        this.isHolded = false;
      }
    },
    confirmChangeMarket() {
      if (confirm('Are you sure?')) {
        this.changeMarketHandler()
        this.isHolded = false;
      }
    },
    confirmBump() {
      if (confirm('Are you sure?')) {
        this.bumpOrderNode()
        this.isHolded = false;
      }
    },
    confirmRestart() {
      if (confirm('Are you sure?')) {
        this.restartOrderNode()
        this.isHolded = false;
      }
    },
    refundDuplicateHandler() {
      this.setNotification({name: 'ID ' + this.exchange.id + ', refund started'});
      this.emitter.off('update-notification');
      this.emitter.on('update-notification', () => {
        if (this.Notification && this.Notification.name) {
          OrderService.getRefundDuplicate(this.exchange.id, this.refundAddress, this.selectedOption.option)
            .then((res) => {
              this.successPopup('Refunded');
            })
            .catch(error => {
              if (error) {
                this.errorPopup( error.response.data.message);
              }
            });
        }
      })
    },
    restartOrderNode() {
      this.setNotification({name: 'ID ' + this.exchange.id + ', refund started'});
      this.emitter.off('update-notification');
      this.emitter.on('update-notification', () => {
        if (this.Notification && this.Notification.name) {
          OrderService.getRefundOrder(this.exchange.id)
              .then((res) => {
                this.successPopup('refund');
              })
              .catch(error => {
                if (error) {
                  this.errorPopup( error.response.data.message);
                }
              });
        }
      })
    },
    refundOrderNode(id) {
      this.setNotification({name: 'ID ' + this.exchange.id + ', restart started'});
      this.emitter.off('update-notification');
      this.emitter.on('update-notification', () => {
        if (this.Notification && this.Notification.name) {
          OrderService.getRestartOrder(this.exchange.id, this.refundAddress, this.selectedOption.option)
              .then((res) => {
                this.successPopup('restart');
              })
              .catch(error => {
                if (error) {
                  this.errorPopup( error.response.data.message);
                }
              });
        }
      })
    },
    bumpOrderNode() {
      this.setNotification({name: 'ID ' + this.exchange.id + ', bump started'});
      this.emitter.off('update-notification');
      this.emitter.on('update-notification', () => {
        if (this.Notification && this.Notification.name) {
          OrderService.getBumpOrder(this.exchange.id, this.selectedOption.option)
              .then((res) => {
                this.successPopup('bump')
              })
              .catch(error => {
                if (error) {
                  this.errorPopup( error.response.data.message)
                }
              });
        }
      })
    },
    changeMarketHandler() {
      const query = { exchangeName: this.selectedMarket, feeOption: this.selectedOption.option }
      const checkQuery =
        `?coinFrom=${this.exchange.from.shortName}&coinTo=${this.exchange.to.shortName}&networkFrom=${this.exchange.addressFromNetwork}&networkTo=${this.exchange.addressToNetwork}&exchangeName=${this.selectedMarket}&orderID=${this.exchange.id}`

      OrderService.checkPairAvailability(checkQuery).then((res) => {
        if (res.data.result === false) {
          this.$store.commit('setNotificationNode', {name: 'Error: this pair unavailable for change to ' + this.selectedMarket + ' market'});
        } else {
          OrderService.changeMarket(this.exchange.id, query)
            .then((res) => {
              this.$store.commit('setNotificationNode', {name: 'ID ' + this.exchange.depositNodeId + ' deposit successfully sent'});
              this.emitter.off('update-notification-node');
              this.emitter.on('update-notification-node', () => {
                this.exchangeInfo(this.exchange.id);
              })
            })
            .catch(error => {
              if (error) {
                this.errorPopup( error.response.data.message)
              }
            });
        }
      }).catch(error => {
          if (error) {
            this.errorPopup( error.response.data.message)
          }
        });
    },
    onCopyAddress() {
      this.copyText = 'Copied to clipboard!';

      this.isSuccessCopy = true;

      setTimeout(() => {
        this.isSuccessCopy = false
      }, 2000);
    },
    successPopup(val) {
      this.$store.commit('setNotificationNode', {name: 'ID ' + this.exchange.depositNodeId + ' node ' + val + ' completed'});
      this.emitter.off('update-notification-node');
      this.emitter.on('update-notification-node', () => {
       this.exchangeInfo(this.exchange.id);
      })
    },
    errorPopup(val) {
      this.$store.commit('setError', {
        name: 'Error',
        value: val,
      });
    },
    exchangeInfo(exchangeId) {
      OrderService.getOrderAdminById(exchangeId).then((res) => {
        this.$store.commit('setExchangeData', res.data.result);
      })

      OrderService.getOrderActivity(exchangeId).then((res) => {
        this.$store.commit('setOrderStatusActivityData', res.data.result);
      })
    },
    getMarket() {
      OrderService.getAvailableExchanges(this.exchange.id).then((res) => {
        if (res.status === 200) {
          this.marketsList = res.data.result.filter(market => market !== this.exchangeData.binanceAcc);
          this.selectedMarket = this.marketsList[0]
        }
      }).catch((error) => {
        console.log(error.response.data.message)
      });
    },
  }
}
</script>

<style lang="scss">
.selected-list {
  position: relative;
  width: 100%;

  .input {
    justify-content: space-between;
    padding-right: 10px !important;
    background: white;
    text-transform: capitalize;

    &-text {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &_bold {
        color: #1B1A1C;
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
      }
    }
  }

  .dropdown {
    position: absolute;
    overflow: visible;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    border-radius: 12px;
    background: var(--grayscale-white, #FFF);
    box-shadow: 0 4px 24px 0 rgba(15, 0, 58, 0.12);
    z-index: 1;

    &--item {
      padding: 12px 34px 12px 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      transition: all .3s ease-in-out;
      cursor: pointer;

      &:hover {
        background: #F8F8F8;
      }


      .text {
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-width: 342px;

        .name {
          color: var(--black, #1B1A1C);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          text-transform: capitalize;
        }

        .desc {
          color: #7C86A0;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }

      .value {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &-cost {
          color: #1B1A1C;
          text-align: right;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }

        &-usdt {
          color: #7C86A0;
          text-align: right;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }
}
</style>
