<!-- LevelItem.vue -->
<template>
  <div class="level--item_block">
    <div
      v-if="item.level > 0"
      class="separator"
    >
      <div class="text text-account">
        Account
      </div>
    </div>
    <div
      v-if="item.level === 2"
      class="separator"
    >
      <div class="text text-node">
        Node
      </div>
    </div>
    <div
      v-if="item.category !== 10"
      class="level--item "
    >
      <div
        class="status-comment"
        :class="{'status-comment_red': containsAMLCheck(item)}"
      />
      <div class="comment">
        <div class="comment_block">
          <div class="data">
            <div
              v-if="item.authorName"
              class="user"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clip-path="url(#clip0_4241_48740)">
                  <path
                    d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 4.5C10.4475 4.5 11.625 5.6775 11.625 7.125C11.625 8.5725 10.4475 9.75 9 9.75C7.5525 9.75 6.375 8.5725 6.375 7.125C6.375 5.6775 7.5525 4.5 9 4.5ZM9 15C7.4775 15 5.6775 14.385 4.395 12.84C5.6625 11.85 7.26 11.25 9 11.25C10.74 11.25 12.3375 11.85 13.605 12.84C12.3225 14.385 10.5225 15 9 15Z"
                    fill="#DFE0EB"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4241_48740">
                    <rect
                      width="18"
                      height="18"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
              {{ item.authorName }}
            </div>
            {{ dateFormatter(item.createdOn) }}
          </div>
          <div
            class="head"
            :class="{'head-aml': containsAMLCheck(item)}"
          >
            <div class="status_block">
              <div
                v-if="!containsAMLCheck(item) && item.statusOld"
                class="status"
                :class="{
                  'status--empty': item.statusOld === 'Remaining',
                  'status--pay': item.statusOld === 'Pay',
                  'status--exchange': item.statusOld === 'Exchange',
                  'status--withdrawal': item.statusOld === 'Withdrawal',
                  'status--completed': item.statusOld === 'Completed',
                  'status--overdue': item.statusOld === 'Overdue',
                  'status--returned': item.statusOld === 'Returned',
                  'status--hold': item.statusOld === 'Hold',
                  'status--profit': item.statusOld === 'Profit',
                }"
              >
                {{ item.statusOld }}
              </div>
              <div
                v-if="item.statusNew && item.statusOld"
                class="arrow"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1_48517)">
                    <path
                      d="M7.49891 4.5L6.44141 5.5575L9.87641 9L6.44141 12.4425L7.49891 13.5L11.9989 9L7.49891 4.5Z"
                      fill="#DFE0EB"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_48517">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div
                v-if="!containsAMLCheck(item) && item.statusNew"
                class="status"
                :class="{
                  'status--empty': item.statusNew === 'Remaining',
                  'status--pay': item.statusNew === 'Pay',
                  'status--exchange': item.statusNew === 'Exchange',
                  'status--withdrawal': item.statusNew === 'Withdrawal',
                  'status--completed': item.statusNew === 'Completed',
                  'status--overdue': item.statusNew === 'Overdue',
                  'status--returned': item.statusNew === 'Returned',
                  'status--hold': item.statusNew === 'Hold',
                  'status--profit': item.statusNew === 'Profit',
                }"
              >
                {{ item.statusNew }}
              </div>
            </div>

            <div
              class="title"
            >
              <div
                v-if="item.category === 3"
                class="aml"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="24"
                    height="24"
                    rx="6"
                    fill="#FF783E"
                    fill-opacity="0.2"
                  />
                  <g clip-path="url(#clip0_4241_48775)">
                    <path
                      d="M17.8346 7.34199L16.6596 6.16699L12.0013 10.8253L7.34297 6.16699L6.16797 7.34199L10.8263 12.0003L6.16797 16.6587L7.34297 17.8337L12.0013 13.1753L16.6596 17.8337L17.8346 16.6587L13.1763 12.0003L17.8346 7.34199Z"
                      fill="#FF783E"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4241_48775">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(2 2)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div
                v-if="item.category === 5"
                class="aml"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="24"
                    height="24"
                    rx="6"
                    fill="#9FA2B4"
                    fill-opacity="0.2"
                  />
                  <g clip-path="url(#clip0_1_48834)">
                    <path
                      d="M18.6654 9.50033V7.83366C18.6654 6.91699 17.9154 6.16699 16.9987 6.16699H14.4987C14.4987 4.78366 13.382 3.66699 11.9987 3.66699C10.6154 3.66699 9.4987 4.78366 9.4987 6.16699H6.9987C6.08203 6.16699 5.33203 6.91699 5.33203 7.83366V9.50033C3.9487 9.50033 2.83203 10.617 2.83203 12.0003C2.83203 13.3837 3.9487 14.5003 5.33203 14.5003V17.8337C5.33203 18.7503 6.08203 19.5003 6.9987 19.5003H16.9987C17.9154 19.5003 18.6654 18.7503 18.6654 17.8337V14.5003C20.0487 14.5003 21.1654 13.3837 21.1654 12.0003C21.1654 10.617 20.0487 9.50033 18.6654 9.50033ZM16.9987 17.8337H6.9987V7.83366H16.9987V17.8337ZM9.4987 12.8337C8.80703 12.8337 8.2487 12.2753 8.2487 11.5837C8.2487 10.892 8.80703 10.3337 9.4987 10.3337C10.1904 10.3337 10.7487 10.892 10.7487 11.5837C10.7487 12.2753 10.1904 12.8337 9.4987 12.8337ZM15.7487 11.5837C15.7487 12.2753 15.1904 12.8337 14.4987 12.8337C13.807 12.8337 13.2487 12.2753 13.2487 11.5837C13.2487 10.892 13.807 10.3337 14.4987 10.3337C15.1904 10.3337 15.7487 10.892 15.7487 11.5837ZM8.66536 14.5003H15.332V16.167H8.66536V14.5003Z"
                      fill="#9FA2B4"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_48834">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(2 2)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div
                v-if="item.category === 11"
                class="aml"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="24"
                    height="24"
                    rx="6"
                    fill="#02C076"
                    fill-opacity="0.2"
                  />
                  <g clip-path="url(#clip0_4519_50481)">
                    <path
                      d="M7 16.6582L8.175 17.8332L12 14.0165L15.825 17.8332L17 16.6582L12 11.6582L7 16.6582Z"
                      fill="#02C076"
                    />
                    <path
                      d="M7 11.166L8.175 12.341L12 8.52435L15.825 12.341L17 11.166L12 6.16602L7 11.166Z"
                      fill="#02C076"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4519_50481">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(2 2)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div
                v-if="item.category === 12"
                class="aml"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="24"
                    height="24"
                    rx="6"
                    fill="#FF783E"
                    fill-opacity="0.2"
                  />
                  <g clip-path="url(#clip0_4519_50460)">
                    <path
                      d="M7 7.3418L8.175 6.1668L12 9.98346L15.825 6.1668L17 7.3418L12 12.3418L7 7.3418Z"
                      fill="#FF783E"
                    />
                    <path
                      d="M7 12.834L8.175 11.659L12 15.4757L15.825 11.659L17 12.834L12 17.834L7 12.834Z"
                      fill="#FF783E"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4519_50460">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="matrix(1 0 0 -1 2 22)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div
                v-if="item.category === 4"
                class="aml"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="24"
                    height="24"
                    rx="6"
                    fill="#FF783E"
                    fill-opacity="0.2"
                  />
                  <g clip-path="url(#clip0_1_48779)">
                    <path
                      d="M7 7.3418L8.175 6.1668L12 9.98346L15.825 6.1668L17 7.3418L12 12.3418L7 7.3418Z"
                      fill="#FF783E"
                    />
                    <path
                      d="M7 12.833L8.175 11.658L12 15.4747L15.825 11.658L17 12.833L12 17.833L7 12.833Z"
                      fill="#FF783E"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_48779">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="matrix(1 0 0 -1 2 22)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div
                v-if="item.category === 500"
                class="aml"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="24"
                    height="24"
                    rx="6"
                    fill="#FF783E"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M11.168 14.4993H12.8346V16.166H11.168V14.4993ZM11.168 7.83268H12.8346V12.8327H11.168V7.83268ZM11.993 3.66602C7.39297 3.66602 3.66797 7.39935 3.66797 11.9993C3.66797 16.5993 7.39297 20.3327 11.993 20.3327C16.6013 20.3327 20.3346 16.5993 20.3346 11.9993C20.3346 7.39935 16.6013 3.66602 11.993 3.66602ZM12.0013 18.666C8.31797 18.666 5.33464 15.6827 5.33464 11.9993C5.33464 8.31602 8.31797 5.33268 12.0013 5.33268C15.6846 5.33268 18.668 8.31602 18.668 11.9993C18.668 15.6827 15.6846 18.666 12.0013 18.666Z"
                    fill="#FF783E"
                  />
                </svg>
              </div>
              {{ item.commentTheme }}
            </div>
          </div>
          <div class="info">
            <div
              class="info--item"
              v-html="formatMessage(item.message)"
            />
          </div>
          <div class="buttons">
            <div
              class="copy"
              @click="copyTextToClipboard(item.message)"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4241_48848)">
                  <path
                    d="M6.94412 1.83301H15.8514C16.6679 1.83301 17.3359 2.50105 17.3359 3.31755V13.7094H15.8514V3.31755H6.94412V1.83301ZM4.7173 4.8021H12.8823C13.6988 4.8021 14.3668 5.47014 14.3668 6.28664V16.6785C14.3668 17.495 13.6988 18.163 12.8823 18.163H4.7173C3.9008 18.163 3.23276 17.495 3.23276 16.6785V6.28664C3.23276 5.47014 3.9008 4.8021 4.7173 4.8021Z"
                    fill="#DFE0EB"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4241_48848">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="matrix(-1 0 0 1 20 0)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div
              v-if="!isAddComment && !item.replies.length"
              class="add"
              @click="isAddComment = true"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4241_48853)">
                  <path
                    d="M1.6737 3.33366C1.6737 2.41699 2.41536 1.66699 3.33203 1.66699H16.6654C17.582 1.66699 18.332 2.41699 18.332 3.33366V13.3337C18.332 14.2503 17.582 15.0003 16.6654 15.0003H4.9987L1.66536 18.3337L1.6737 3.33366ZM5.83203 9.16699H9.16536V12.5003H10.832V9.16699H14.1654V7.50033H10.832V4.16699H9.16536V7.50033H5.83203V9.16699Z"
                    fill="#DFE0EB"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4241_48853">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="matrix(-1 0 0 1 20 0)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <div
          v-for="(reply, index) in item.replies"
          :key="index"
          class="comment_user"
        >
          <div class="avatar">
            <Avatar :img="reply.authorAvatar" />
          </div>
          <div class="comment_user-text">
            <div class="head">
              <div class="login">
                {{ reply.authorName }}
              </div>
              <div class="date">
                {{ dateFormatter(reply.createdOn) }}
              </div>
            </div>
            <div class="text">
              {{ reply.message }}
            </div>
          </div>
        </div>
        <div
          v-if="isAddComment"
          class="comment_user"
        >
          <div class="avatar">
            <Avatar :img="avatar.avatar" />
          </div>
          <div class="comment_user-text">
            <span
              class="text textarea"
              role="textbox"
              :class="{'not-empty': comment.trim().length > 0}"
              contenteditable
              @input="updateValue"
              @blur="sanitizeContent"
            />
            <div
              class="buttons"
            >
              <div
                class="save"
                @click="createComment(item.id)"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_4241_49091)">
                    <path
                      d="M7.4987 13.5003L3.9987 10.0003L2.83203 11.167L7.4987 15.8337L17.4987 5.83366L16.332 4.66699L7.4987 13.5003Z"
                      fill="#0A68F7"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4241_49091">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>

                Save
              </div>
              <div
                class="cancel"
                @click="isAddComment = false; comment = '' "
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_4241_49094)">
                    <path
                      d="M21.8346 11.342L20.6596 10.167L16.0013 14.8253L11.343 10.167L10.168 11.342L14.8263 16.0003L10.168 20.6587L11.343 21.8337L16.0013 17.1753L20.6596 21.8337L21.8346 20.6587L17.1763 16.0003L21.8346 11.342Z"
                      fill="#FF783E"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4241_49094">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(6 6)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="level--item "
    >
      <div
        class="status-comment"
        :class="{'status-comment_red': containsAMLCheck(item)}"
      />
      <div class="comment">
        <div
          class="comment_user"
        >
          <div class="avatar">
            <Avatar :img="item.authorAvatar" />
          </div>
          <div class="comment_user-text">
            <div class="head">
              <div class="login">
                {{ item.authorName }}
              </div>
              <div class="date">
                {{ dateFormatter(item.createdOn) }}
              </div>
            </div>
            <div class="text">
              {{ item.message }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <CopyNotification
    v-if="isSuccessCopy"
    :text="copyText"
  />
</template>

<script>
import dayjs from "dayjs";
import CopyNotification from "@/pages/notification/CopyNotification.vue";
import OrderService from "@/services/order.service";
import {mapGetters} from "vuex";
import Avatar from "@/components/admin/AvatarComponent.vue";

export default {
  name: "LevelItem",
  components: {Avatar, CopyNotification},
  props: {
    item: {
      type: Object,
      default: null
    },
    addLastLog: {
      type: Function,
      required: true
    },
    orderData: {
      type: Object,
      default: null
    },
    avatar: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dateFormat: 'DD.MM.YY - HH:mm',
      copyText: '',
      isSuccessCopy: true,
      isAddComment: false,
      comment: ''
    }
  },
  computed: {
    ...mapGetters({
      UserProfileData: 'getUserProfileData'
    })
  },
  methods: {
    sanitizeContent(event) {
      const el = event.target;
      if (el.innerText.trim() === '') {
        el.innerHTML = '';
      }
    },
    containsAMLCheck(log) {
      if (log && log.commentTheme) {
        return log.commentTheme.includes("AML Check");
      }
    },
    formatMessage(message) {
      return message.replace(/\n/g, '<br>');
    },
    dateFormatter(value) {
      const localDate = dayjs.utc(value).local();

      return localDate.format(this.dateFormat)
    },
    onCopyAddress() {
      this.copyText = 'Copied to clipboard!';

      this.isSuccessCopy = true;

      setTimeout(() => {
        this.isSuccessCopy = false
      }, 2000);
    },
    copyTextToClipboard(text) {
      navigator.clipboard.writeText(text)
          .then(() => {
            this.onCopyAddress();
          })
          .catch(err => {
            console.error('Could not copy text: ', err);
          });
    },
    updateValue(event) {
      this.comment = event.target.innerText;
    },
    createComment() {
      OrderService.createComment(this.orderData.id, this.comment, this.item.id).then(res => {
        if (res) {
          this.comment = '';
          this.isAddComment = false
          this.addLastLog();
        }
      });
    },
  }
}
</script>
